import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import axios from "axios";
import HomePageSlider from "./HomePageSlider";

const HomeLatestBuyer = () => {
  const [latestBuyer, setLatestBuyer] = useState([]);
  const getLatestBuyer = async () => {
    try {
      const res = await axios.get(`/publicChannel/latestBuyers`);
      if (res) {
        setLatestBuyer(res.data.channels);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestBuyer();
  }, []);

  return (
    <Row  id='new-home-page-padding' style={{backgroundColor:"#fff"}}>
      <Col lg="12" className="p-0">
        <Card style={{padding:"0", margin:"0"}}>
          <div
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              color: "rgb(50, 50, 93)", 
              paddingBottom:"3rem",
              fontWeight: "600",
              fontFamily: "inherit",
            }}
          >
            Latest Buyers
          </div>
          <CardBody id="" style={{ padding:"0",  backgroundColor:"#fff" }}>
            <HomePageSlider
              channels={latestBuyer}
              sliderTitle={"Latest Buyers"}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default HomeLatestBuyer;
