import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { history } from '_helpers';
import { commonService } from './common.service';
import { store } from '_helpers';

export const eventService = {
  eventRegistration,
  eventPaymentRegistration
}

const userData = JSON.parse(localStorage.getItem('CHuser'));


async function eventRegistration(payment) {
  const userData = JSON.parse(localStorage.getItem('CHuser'));
  let event_redux = store.getState();
  let event = event_redux.event;
  // console.log("event.eventPackage:",event);
  let eventDetails = event?.eventDetails;
  let eventPackage = event?.eventPackage;
  let eventContacts = event?.eventContacts;
  let eventAmt = event?.eventAmt;


  if (event) {

    let data = {
      contactId: eventContacts,
      eventId: eventDetails?.eventId,
      isFromFO: payment?.isFromFO,
      eventType: payment?.eventType,
      additionalMembers: event?.additionalMember,
      packageId: eventPackage?.packageId,
      paymentStatus: payment?.paymentStatus,
      paymentMethod: payment?.paymentMethod,
      paymentTransactionId: payment?.paymentTransactionId,
      marketingOptions: event?.marketingOptions?.map(x => x.optionId),
      packageOptions: event?.packageOptions?.map(x => x.optionId),
      isDelegate: payment?.isDelegate
    }


    let eventData = {
      name: userData.firstName + ' ' + userData.lastName,
      email: userData.email,
      eventId: eventPackage?.eventId,
      contactId: userData.contactId,
      templateId: eventPackage.regTemplate,
      profileurl: process.env.REACT_APP_LOCALSITE + 'login'
    };
    await axios.post('channel/postEventRegistration', data).then(async res => {
      if(res.data == 'approvalWaiting') {
        ToastsStore.warning('You cannot apply yet for the event as one of your colleague is currently pending for approval or payment, please check later');
      } else {  
          if (eventPackage.regTemplate && !payment.isDelegate) {
            await axios.post('mailerservice/eventsRegisterMail', eventData);
          }
          else if (payment.isDelegate) {

            
            if(+eventPackage.packageId > 0) {
                let delegate = {
                  channelId: userData.channelId,
                  contactId: userData.contactId,
                  eventId: eventPackage?.eventId,
                  packageId: eventPackage.packageId,
                  profileurl: process.env.REACT_APP_LOCALSITE + 'login',
                }
                await axios.post('mailerservice/eventsDelegateMail', delegate);
            }
          }
          
          if(payment?.sellerDetail) {
                if (payment?.sellerDetail['targetCountries']?.length > 0) {


                  let targetLocation = []; let locations = [];

                  // if (userData.channelTypeId === 4 || userData.channelTypeId === 3 || userData.channelTypeId == 5)
                  targetLocation = payment.sellerDetail['targetCountries'].map(x => +x.regionCountryJCTId);

                  locations.push({
                    locationType: 'TARGET',
                    isUpdate: true,
                    location: targetLocation,
                  });
                  setSellingLocation({ locations: locations, no_of_skus: payment.sellerDetail['no_of_skus'] });
                }
          }
          
          if(eventDetails.alreadyRegistered > 0){

              if(eventDetails.alreadyRegistered > 0 && payment?.appData){
                axios.put('/channelAdmin/channelApproval', payment?.appData).then(async res => {
                    ToastsStore.success('Complete your payment to procees further');
                    
                });              
              }
              
              let dashboard = userData?.channelTypeId == 2 ? 'vendorDashboard' : 'BuyerDashboard';
              
              //history.push('/admin/' + dashboard + '/eventSubscription/' + eventDetails?.eventId);
                history.push('/admin/events');
                // console.log('approve secondary user ===> ' );
                // console.log(appData)
              
          }

        }

      //history.push('/admin/eventPending');//history.push('/admin/PaymentSuccess');
    }).catch(err => {
      //console.log('something went wrong' , err);
      ToastsStore.error('something went wrong...') });


    // if (result.data) {
    //   // await axios.post('channel/foToEventCatalyst', eventContacts).then(res => {
    //   //     eventCatalystRegister(userData, res.data, eventDetails?.event_catalyst_id, eventDetails?.eventId) ;
    //   //   }).catch(err => ToastsStore.warning('something went wrong'));
    //   // console.log("eventManagess:",window.location.pathname.includes('eventManage'));
    //   // if (!window.location.pathname.includes('eventManage')) {
    //     // console.log("resultss:",result);



    //   // }

    //   // if (window.location.pathname.includes('eventManage')) {
    //     // console.log("resultss1:",result);
    //   //   history.push('/admin/events');
    //   // } else {
    //     // console.log("resultss2:",result);

    //   // }

    // }

  } else {
    return 'something went wrong';
  }

}

async function eventPaymentRegistration(paymentType, paymentDetails, paymentResult) {
  const userData = JSON.parse(localStorage.getItem('CHuser'));
  let event_redux = store.getState();
  let event = event_redux.event;
  // console.log("event.eventPackage:",event);
  let eventDetails = event?.eventDetails;
  let eventPackage = event?.eventPackage;
  let eventContacts = event?.eventContacts;
  let eventAmt = event?.eventAmt;

  let eventData;
  if (event) {

    // let data = {
    //   contactId: eventContacts,
    //   eventId: eventDetails?.eventId,
    //   isFromFO:payment?.isFromFO,
    //   eventType:payment?.eventType,  
    //   additionalMembers: event?.additionalMember,
    //   packageId: eventPackage?.packageId,
    //   paymentStatus: payment?.paymentStatus,
    //   paymentMethod: payment?.paymentMethod,
    //   paymentTransactionId: payment?.paymentTransactionId,
    //   marketingOptions: event?.marketingOptions?.map(x => x.optionId),
    //   packageOptions: event?.packageOptions?.map(x => x.optionId)
    // }

    // let eventData = {
    //   // name: userData.firstName + ' ' + userData.lastName,
    //   // email: userData.email,
    //   contactId: userData.contactId,
    //   channelId: userData.channelId,
    //   packageId: payment?.packageId,
    //   eventId: payment?.eventId,
    //   paymentTransactionId: payment?.paymentTransactionId,
    //   profileurl: process.env.REACT_APP_LOCALSITE,
    //   finalAmount: (parseFloat(payment?.finalAmount) + parseFloat(payment?.taxAmount)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
    //   subTotal: parseFloat(payment?.subTotal).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
    //   taxAmount: parseFloat(payment?.taxAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
    //   vatNumber: payment?.vatNumber,
    //   actualAmount: payment?.actualAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
    //   offer: payment?.offers,
    //   paymentMethod: payment?.paymentMethod,
    //   paymentStatus: payment?.paymentStatus,
    //   paymentOption: payment?.totalSplit.length > 1 ? 2 : 1,
    //   totalSplit: payment?.totalSplit,
    //   splitId: payment?.splitId,
    //   eventName: payment?.eventName,
    //   isSplit: payment.isSplit,


    //   //templateId: eventDetails.regTemplate
    // };

    eventData = {
      isPaid: '1',
      paymentStatus: '1',
      paymentMethod: paymentType,
      type: (paymentType == 1) ? '' : 'Wire Transfer',
      eventName: eventDetails?.event_name,
      eventId: paymentDetails.eventId,
      profileurl: process.env.REACT_APP_LOCALSITE,
      totalDiscountedAmt: paymentDetails?.totalDiscountedAmt,
      subTotal: parseFloat(paymentDetails?.finalAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
      taxAmount: parseFloat(paymentDetails?.taxAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
      vatNumber: paymentDetails?.vatNumber,
      actualAmount: paymentDetails?.actualAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
      paymentTransactionId: (paymentType == 2) ? 'Wire Transfer' : paymentResult.paymentIntent.id,
      contactId: userData.contactId,
      channelId: userData.channelId,
      packageId: paymentDetails.package[0].packageId,
      package: paymentDetails?.package,
      paymentOption: (paymentDetails?.totalSplit) ? paymentDetails?.totalSplit.length > 1 ? 2 : 1 : 1,
      coupan_code: paymentDetails.coupan_code,
      isAddon: false,
      eventChannelId: paymentDetails.eventChannelId,
    }

    if (paymentDetails.isPrimary) {
      Object.assign(eventData, {

        splitId: paymentDetails.splitId,
        totalSplit: paymentDetails.totalSplit,
        isSplit: paymentDetails.paymentMethod == 1 ? false : true,
        offers: paymentDetails?.offers?.filter(o => o.offerType == 2 && o.offerParam == paymentDetails.coupan_code).length ? paymentDetails?.offers.filter(o => o.offerParam == paymentDetails.coupan_code) : paymentDetails?.offers?.filter(o => o.offerType == 1),
        //   paymentTransactionId: (paymentType == 1)?paymentResult.paymentIntent.id:'Wire Transfer',
        pennyInvoiceId: paymentDetails.pennyInvoiceId,
        finalAmount: (parseFloat(paymentDetails?.finalAmount) + parseFloat(paymentDetails.taxAmount)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')

      });
    } else if (paymentDetails.isSecondaryPackage) {
      Object.assign(eventData, {

        offer: paymentDetails?.offers?.filter(o => o.offerType == 2 && o.offerParam == paymentDetails.coupan_code).length ? paymentDetails?.offers.filter(o => o.offerParam == paymentDetails.coupan_code) : paymentDetails?.offers?.filter(o => o.offerType == 1),
        totalSplit: [],//payment?.totalSplit,
        splitId: null,//payment?.splitId,
        isSplit: false,
        //templateId: eventDetails.regTemplate,
        finalAmount: (parseFloat(paymentDetails?.intentAmount)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')
      });
    } else {
      Object.assign(eventData, {

        offer: [],
        paymentOption: 1,//payment?.totalSplit.length>1?2:1,
        totalSplit: [],//payment?.totalSplit,
        splitId: null,//payment?.splitId,
        isSplit: false,
        isAddon: true,
        finalAmount: (parseFloat(paymentDetails?.finalAmount) + parseFloat(paymentDetails.taxAmount)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')
      });

    }


    if (eventData?.paymentMethod == 2) {
      //if (paymentDetails.isPrimary) {
        let notifyData = {
          contactId: userData.contactId,
          channelId: userData.channelId,
          eventId: eventData?.eventId,
          channelType: userData.channelTypeId,
          finalAmount: eventData?.finalAmount,
        }
        await axios.post('channel/updateEventPayment', eventData).then(async res => {
          await axios.post('mailerservice/eventPaymentNotifyMail', notifyData);
        }).catch(err => ToastsStore.error('something went wrong...'));
      // } else {
      //   await axios.post('mailerservice/eventPaymentMail', eventData);
      // }
    } else if (eventData?.paymentMethod == 1) { //stripe payment
      //if (paymentDetails.isPrimary) {
        await axios.post('channel/updateEventPayment', eventData).then(async res => {
          await axios.post('mailerservice/eventPaymentMail', eventData);
          await axios.post('mailerservice/eventPaymentNotificationMailToAdmin', eventData);
          // await axios.post('/pennylane/mailInvoice',{invoiceId:payment.pennyInvoiceId}).then(res => {
          //   console.log("penny:",res.data);
          // })
          // await axios.post('/pennylane/updateInvoice',{invoiceId:payment.pennyInvoiceId}).then(res => {
          //   console.log("penny:",res.data);
          // })
        }).catch(err => ToastsStore.error('something went wrong...'));
      // } else {
      //   await axios.post('mailerservice/eventPaymentMail', eventData);
      // }
    }


    return true;

  } else {
    return 'something went wrong';
  }

}

async function setSellingLocation(sellerDetail) {

  await axios.patch('user/updateSellingLocation/', sellerDetail).then(async res => {

  }).catch(err => ToastsStore.error('something went wrong...'));

}


//for event catalyst related operations

const eventCatalystRegister = async (userData, eventCatalystData, eventCatalystId, eventId) => {
  if (eventCatalystData.length) {
    eventCatalystData.map(async x => {

      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }

      let token = await commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          ToastsStore.warning('Something went wrong');
        } else {
          return res.data;
        }
      }).catch(err => {
        console.log('err', err)
      })


      let dec = x.detailDesc ? x.detailDesc : null
      console.log('dec', dec);
      if ((dec === null) || (dec === '')) {
        dec = null;
      } else {
        dec = dec.toString();
        dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
      }

      let data = {
        "participantId": parseInt(x.contactId),
        "firstName": x.firstName,
        "lastName": x.lastName,
        "email": x.email,
        "companyName": x.companyName,
        "companyId": parseInt(userData.channelId),
        "eventId": eventCatalystId,
        "status": "Confirmed",
        "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
        "typeId": parseInt(userData.channelTypeId),
        "timezone": "",
        "matchingScore": [],
        "country": x.isoCode,
        "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelDetailView/' + userData.channelId,
        "photoUrl": x.profileimage,
        "mobile": x.mobileNo,
        "jobTitle": x.jobTitle,
        "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
        "logoUrl": x.companylogo,
        "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
        "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
        "profileType": x.ProfileType ? x.ProfileType : null,
        "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
        "detailDesc": dec,
        "password": x.password ? x.password : null
      }
      // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
      let body = {
        token: token, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
        url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
        body: data
      }

      await commonService.postService(body, 'account/postEventRegister').then(async res => {
        await setEventContact(+x.contactId)
        await setEventParticipantStatus(eventId, +userData.channelId, +x.contactId);
        // await window.open(res.data.pageUrl, '_blank');
        // getEventList();
      });

    })
  }
}

const setEventContact = async (contactId) => {
  await commonService.getServices('channel/setEventContact/' + contactId).then(res => {

  }, err => {
    console.log('err', err)
  });
}

const setEventParticipantStatus = async (eventId, channelId, contactId) => {
  let data = {
    eventId: eventId,
    channelId: channelId,
    contactId: contactId
  }
  await commonService.postService(data, 'channel/setEventParticipantStatus/').then(res => {

  }, err => {
    console.log('err', err)
  });
}