import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import axios from "axios";
import HomePageSlider from "./HomePageSlider";


const HomeLatestVendor = (props) => {
  const [latestVendor, setLatestVendor] = useState([]);
  const{buyerPage} = props;
  const getLatestVendor = async () => {
    try {
      const res = await axios.get(`/publicChannel/latestVendors`);
      if (res) {
        setLatestVendor(res.data.channels);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestVendor();
  }, []);

  return (
    <Row id='new-home-page-padding' style={{ backgroundColor:buyerPage === true ? "#fff" : "#f7f7f7"}}> 
      <Col lg="12" className="p-0">
        <Card style={{padding:"0", margin:"0"}}>
        <div
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              color: "rgb(50, 50, 93)",
              backgroundColor:buyerPage === true ? "#fff" : "#f7f7f7",
              paddingBottom: "3rem",
              fontWeight: "600",
              fontFamily: "inherit",
            }}
          >
               Latest Vendors
          </div>
          <CardBody id="" style={{ padding:"0", backgroundColor:buyerPage === true ? "#fff" : "#f7f7f7"}}>
            <HomePageSlider
              channels={latestVendor}
              sliderTitle={"Latest Vendors"}
              buyerPage={buyerPage}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default HomeLatestVendor;
