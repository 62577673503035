import React, { useState, useMemo, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Card, CardBody, Form, Container, Col, Row, Button } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "_components/Stripe/styles.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import useResponsiveFontSize from "_components/Stripe/useResponsiveFontSize.js";
import { commonService } from '_services/common.service';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector } from 'react-redux';
import { history } from '../../../../_helpers';
import { Spinner } from "views/Hoc/Spinner";
import FormGroup from '@material-ui/core/FormGroup';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { eventService } from "_services";
import axios from 'axios';

//import "./styles.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      },
      hidePostalCode: true
    }),
    [fontSize]
  );

  return options;
};
const CardFormInter = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  let [isLoading, setLoading] = useState(false);
  const redux_data = useSelector(state => state.event);
  let [iscardComplete, setIsCardComplete] = useState(false);
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  useEffect(() => {
    // const subId = redux_data.select_plan;
    // if(!subId){
    //   history.push('/admin/Subscription');
    //   return;
    // }
  }, [])
  const handleSubmit = async event => {
    const paymentDetails = redux_data.eventPayment;
    const eventDetails = redux_data?.eventDetails;
    //console.log("eventPayment:", paymentDetails,);

    let userData = JSON.parse(localStorage.getItem('CHuser'));

    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements || !iscardComplete) {
      return;
    }
    let eventChannelId = paymentDetails.totalSplit[0].eventChannelId;
    let data = {
      amount: parseInt(paymentDetails.actualAmount) + parseInt(paymentDetails.taxAmount),
      package: paymentDetails.package[0].packageName,
      eventName: eventDetails?.event_name ? eventDetails.event_name : paymentDetails.event_name
    }
    let res = await commonService.postService(data, 'channel/subscription/eventPaymentIntend').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
    if (!res) {
      return;
    }
    //console.log("payindent:", res);
    let paymentIntentSecret = res.data.paymentIntentSecret;
    const paymentResult = await stripe.confirmCardPayment(paymentIntentSecret, {
      //object: "charge",
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          // address:{
          //   "city": "San Francisco",
          //   "country": "US",
          //   "line1": "510 Townsend St",
          //   "line2": null,
          //   "postal_code": "94103",
          //   "state": "CA"
          // },
          email: userData.email,
          name: userData.firstName + ' ' + userData.lastName, //'Jenny Rosen',

        },

      }

    });
    console.log("payresult:", paymentResult);

    if (paymentResult.error) {
      setLoading(false);
      console.log(paymentResult.error.message);
      ToastsStore.error(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        console.log("payresult:", paymentDetails);

        if (paymentDetails.isPrimary) {
          // let payment = {
          //   isPaid: '1',
          //   paymentStatus: '1',
          //   paymentMethod: 1,
          //   finalAmount: paymentDetails.finalAmount,
          //   taxAmount: paymentDetails.taxAmount,
          //   vatNumber: paymentDetails.vatNumber,
          //   subTotal: paymentDetails.finalAmount,// - paymentDetails.taxAmount,
          //   totalDiscountedAmt: paymentDetails.totalDiscountedAmt,
          //   actualAmount: paymentDetails.actualAmount,
          //   packageId: paymentDetails.packageId,
          //   splitId: paymentDetails.splitId,
          //   totalSplit: paymentDetails.totalSplit,
          //   eventName: data.eventName,
          //   isSplit: paymentDetails.paymentMethod == 1 ? false : true,
          //   offers: paymentDetails?.offers?.filter(o => o.offerType == 2 && o.offerParam == paymentDetails.coupan_code).length ? paymentDetails?.offers.filter(o => o.offerParam == paymentDetails.coupan_code) : paymentDetails?.offers?.filter(o => o.offerType == 1),

          //   //offers: paymentDetails.coupan_code?paymentDetails.offers.filter(x => x.offerType==2):paymentDetails.offers.filter(x => x.offerType==1),


          //   coupan_code: paymentDetails.coupan_code,
          //   eventId: paymentDetails.eventId,
          //   paymentTransactionId: paymentResult.paymentIntent.id,
          //   pennyInvoiceId: paymentDetails.pennyInvoiceId

          // };



          // let result = await eventService.eventPaymentRegistration(payment);
          let result = await eventService.eventPaymentRegistration(1, paymentDetails, paymentResult);
          if (result) {
            const paymentData = { ...paymentDetails, paymentMethod: 1, subscriptionTypeId: 1, isPaid: '1', paymentIndent: paymentResult.paymentIntent.id, eventChannelId: +eventChannelId }
            // const subId = redux_data.select_plan;
            const response = await commonService.postService(
              paymentData
              , 'channel/subscription/eventSubscribe').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
            if (response) {

              ToastsStore.success("Payment Completed Successfully");
              setLoading(false);
              history.push('/admin/PaymentSuccess');
            }
          }

        }
        else if (paymentDetails.isSecondaryPackage) {
          // let payment = {
          //   isPaid: '1',
          //   paymentStatus: '1',
          //   paymentMethod: 1,
          //   finalAmount: paymentDetails.finalAmount,
          //   taxAmount: paymentDetails.taxAmount,
          //   vatNumber: paymentDetails.vatNumber,
          //   subTotal: paymentDetails.finalAmount,// - paymentDetails.taxAmount,
          //   totalDiscountedAmt: paymentDetails.totalDiscountedAmt,
          //   actualAmount: paymentDetails.actualAmount,
          //   packageId: paymentDetails.package[0].packageId,
          //   splitId: paymentDetails.splitId,
          //   totalSplit: paymentDetails.totalSplit,
          //   eventName: data.eventName,
          //   isSplit: paymentDetails.paymentMethod == 1 ? false : true,
          //   offers: paymentDetails?.offers?.filter(o => o.offerType == 2 && o.offerParam == paymentDetails.coupan_code).length ? paymentDetails?.offers.filter(o => o.offerParam == paymentDetails.coupan_code) : paymentDetails?.offers?.filter(o => o.offerType == 1),

          //   //offers: paymentDetails.coupan_code?paymentDetails.offers.filter(x => x.offerType==2):paymentDetails.offers.filter(x => x.offerType==1),


          //   coupan_code: paymentDetails.coupan_code,
          //   eventId: paymentDetails.eventId,
          //   paymentTransactionId: paymentResult.paymentIntent.id,
          //   pennyInvoiceId: paymentDetails.pennyInvoiceId

          // };

          let paidDetails = {
            contactId: userData.contactId,
            channelId: userData.channelId,
            packageId: paymentDetails.package[0].packageId,
            eventId: paymentDetails.eventId,
            paymentMethod: 1,
            splitId: 1,
            paymentDate: new Date(),
            paymentStatus: '1',
            createdAt: new Date(),
            paidAt: new Date(),
            wireTransfer: '0',
            totalAmount: parseInt(paymentDetails.intentAmount),
            percentage: 100,
            splitAmount: parseInt(paymentDetails.intentAmount),
            currCode: paymentDetails.currCode
          }

          const paymentData = { ...paymentDetails, packDesc: paymentDetails.package[0].description.replace(/<[^>]+>/g, ''), paymentMethod: 1, subscriptionTypeId: 1, isPaid: '1', paymentIndent: paymentResult.paymentIntent.id, paidDetails: paidDetails }
          // const subId = redux_data.select_plan;
          const response = await commonService.postService(
            paymentData
            , 'channel/subscription/secondarySubscription').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
          if (response) {

            // let eventData = {
            //   // name: userData.firstName + ' ' + userData.lastName,
            //   // email: userData.email,
            //   contactId: userData.contactId,
            //   channelId: userData.channelId,
            //   packageId: payment?.packageId,
            //   eventId: payment?.eventId,
            //   paymentTransactionId: payment?.paymentTransactionId,
            //   profileurl: process.env.REACT_APP_LOCALSITE,
            //   finalAmount: parseFloat(paymentDetails?.intentAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
            //   subTotal: parseFloat(paymentDetails?.actualAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
            //   taxAmount: parseFloat(payment?.taxAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
            //   vatNumber: payment?.vatNumber,
            //   actualAmount: payment?.actualAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
            //   offer: payment?.offers,
            //   paymentMethod: payment?.paymentMethod,
            //   paymentStatus: payment?.paymentStatus,
            //   paymentOption: payment?.totalSplit?.length > 1 ? 2 : 1,

            //   totalSplit: [],//payment?.totalSplit,
            //   splitId: null,//payment?.splitId,
            //   isSplit: false,

            //   eventName: payment?.eventName,


            //   //templateId: eventDetails.regTemplate
            // };

            //await axios.post('mailerservice/eventPaymentMail', eventData);

            let result = await eventService.eventPaymentRegistration(1, paymentDetails, paymentResult);
            if (result) {
              ToastsStore.success("Payment Completed Successfully");
              setLoading(false);
              history.push('/admin/PaymentSuccess');
            } else {
              ToastsStore.success("Something went wrong");
            }
          }

        } else {

          let paidDetails = {
            contactId: userData.contactId,
            channelId: userData.channelId,
            packageId: paymentDetails.package[0].packageId,
            eventId: paymentDetails.eventId,
            paymentMethod: 1,
            splitId: 1,
            paymentDate: new Date(),
            paymentStatus: '1',
            createdAt: new Date(),
            paidAt: new Date(),
            wireTransfer: '0',
            totalAmount: parseInt(paymentDetails.intentAmount),
            percentage: 100,
            splitAmount: parseInt(paymentDetails.intentAmount),
            currCode: paymentDetails.currCode
          }

          const paymentData = { ...paymentDetails, packDesc: paymentDetails.package[0].description.replace(/<[^>]+>/g, ''), paymentMethod: 1, subscriptionTypeId: 1, isPaid: '1', paymentIndent: paymentResult.paymentIntent.id, paidDetails: paidDetails,eventChannelId: +eventChannelId }
          // const subId = redux_data.select_plan;
          const response = await commonService.postService(
            paymentData
            , 'channel/subscription/eventSubscribe').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
          if (response) {


            if (paymentDetails.isAddon) {

              // let payment = {
              //   isPaid: '1',
              //   paymentStatus: '1',
              //   isAddon: paymentDetails.isAddon,
              //   paymentMethod: 1,
              //   finalAmount: paymentDetails.finalAmount,
              //   taxAmount: paymentDetails.taxAmount,
              //   vatNumber: paymentDetails.vatNumber,
              //   subTotal: paymentDetails.finalAmount,// - paymentDetails.taxAmount,
              //   totalDiscountedAmt: paymentDetails.totalDiscountedAmt,
              //   actualAmount: paymentDetails.actualAmount,
              //   package: paymentDetails.package,
              //   splitId: paymentDetails.splitId,
              //   totalSplit: paymentDetails.totalSplit,
              //   eventName: data.eventName,
              //   isSplit: paymentDetails.paymentMethod == 1 ? false : true,
              //   offers: [],//paymentDetails?.offers.filter(o => o.offerType==2 && o.offerParam==paymentDetails.coupan_code).length?paymentDetails?.offers.filter(o => o.offerParam==paymentDetails.coupan_code):paymentDetails?.offers.filter(o => o.offerType==1 ),

              //   //offers: paymentDetails.coupan_code?paymentDetails.offers.filter(x => x.offerType==2):paymentDetails.offers.filter(x => x.offerType==1),


              //   coupan_code: paymentDetails.coupan_code,
              //   eventId: paymentDetails.eventId,
              //   paymentTransactionId: paymentResult.paymentIntent.id,

              // };

              // let eventData = {
              //   // name: userData.firstName + ' ' + userData.lastName,
              //   // email: userData.email,
              //   contactId: userData.contactId,
              //   channelId: userData.channelId,
              //   package: payment?.package,
              //   eventId: payment?.eventId,
              //   paymentTransactionId: payment?.paymentTransactionId,
              //   profileurl: process.env.REACT_APP_LOCALSITE,
              //   finalAmount: (parseFloat(payment?.finalAmount) + parseFloat(payment.taxAmount)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              //   subTotal: parseFloat(payment?.subTotal).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              //   taxAmount: parseFloat(payment?.taxAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              //   vatNumber: payment?.vatNumber,
              //   actualAmount: payment?.actualAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              //   offer: payment?.offers,
              //   paymentMethod: payment?.paymentMethod,
              //   paymentStatus: payment?.paymentStatus,
              //   paymentOption: 1,//payment?.totalSplit.length>1?2:1,
              //   totalSplit: [],//payment?.totalSplit,
              //   splitId: null,//payment?.splitId,
              //   eventName: payment?.eventName,
              //   isSplit: false,
              //   isAddon: true


              // };

              //await axios.post('mailerservice/eventPaymentMail', eventData);
              let result = await eventService.eventPaymentRegistration(1, paymentDetails, paymentResult);
              if (result) {
                ToastsStore.success("Payment Completed Successfully");
                setLoading(false);
                history.push('/admin/PaymentSuccess');
              }

            }

            //ToastsStore.success("Payment Completed Successfully");
            setLoading(false);
            //history.push('/admin/PaymentSuccess');
          }
        }

      }
    }

    // console.log(localStorage.getItem('stripe_payment_secret'));
    //  await stripe.handleCardPayment(localStorage.getItem('stripe_payment_secret')).then(function(result) {
    //     console.log(result)
    //   });;





    //console.log("[PaymentMethod] na vanthutaga", payload);
  };



  return (<div>
    <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
    {isLoading && <Spinner />}
    <form onSubmit={handleSubmit}>

      Card details
      <CardElement
        options={options}
        onReady={() => {
          console.log("CardElement [ready]");
        }}
        onChange={event => {
          console.log("CardElement [change]", event);
          setIsCardComplete(event.complete);
        }}
        onBlur={() => {
          console.log("CardElement [blur]");
        }}
        onFocus={() => {
          console.log("CardElement [focus]");
        }}
      />

      <button type="submit" disabled={!stripe || !iscardComplete}>
        Pay
      </button>
    </form></div>
  );
};

const EventStripePayment = () => {
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  return (
    <Container className="stripe1">
      <Card>
        <div className=" mt-1 pt-2">
          <div className="stripepay text-center">
            <Row className="justify-content-center pb-2">

              <Col lg="12" >
                <div className="pt-3">
                  <div >
                    <Form className="form-primary" role="form">
                      <Row>
                        <Col lg="12">
                          <FormGroup className="mb-4">


                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                placeholder="Email"
                                value={userData.email}
                                disabled
                                startAdornment={<InputAdornment position="start">
                                  <i class="fas fa-envelope"></i>
                                </InputAdornment>}
                                labelWidth={60}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Elements stripe={stripePromise}>
                            <CardFormInter />
                          </Elements>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </div>
      </Card>
    </Container>

  );
};

function mapState(state) {
  const general_data = state.subscription;
  return general_data;
}

const actionCreators = {
  subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState, actionCreators)(EventStripePayment);
