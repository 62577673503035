import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CardHeader, Card, CardBody, Row, Col, Container } from "reactstrap";
import NextEventPlane from './NextEventPlane';
const NextEventsSlider = () => {


  return (
    <Row id='new-home-page-padding' style={{backgroundColor:"#f7f7f7"}}>
      <Col lg='12' className='p-0' style={{}}>
        <Card style={{backgroundColor:"#f7f7f7", margin:"0"}} >
        <div style={{ fontSize: '1.2rem', textAlign: 'center',paddingBottom:"1.8rem", color: 'rgb(50, 50, 93)', fontWeight:"600", fontFamily:"inherit", backgroundColor:"#f7f7f7"}}>
             Next Events
          </div>
          <CardBody style={{padding:"0 0 3rem 0", backgroundColor:"#f7f7f7"}}>
             <Container>
               <p style={{fontSize:"1rem", textAlign:"center",fontFamily: 'Open Sans, sans-serif', paddingBottom:"1.5rem" }}>ChannelHub is the producer of Channel Summits Events providing the best environment for channel buyers, consumer technology & IT brands to do business and forge new partnerships.
                  Channel Summit events are a high-value opportunity to engage potential business partners through a highly focused series of pre-arranged one-to-one meetings via the ChannelHub platform.</p>

               <p style={{fontSize:"1rem", textAlign:"center", fontFamily: 'Open Sans, sans-serif', margin:"0" }}>Our 'invitation-only' format with a cap on both exhibitor and delegate numbers ensures a safe, relaxed and focused event for all our attendees.
                </p> 
                </Container>
          </CardBody>  
          <CardBody style={{padding:"0", backgroundColor:"#f7f7f7"}}>
            <NextEventPlane />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default NextEventsSlider