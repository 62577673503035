import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import apaclogo from 'assets/img/guestpage/Logo_ChannelConnect APAC.png'

import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import CallIcon from '@material-ui/icons/Call';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';

import Step1 from './Signup.step1';
import Step2 from './Signup.step2';
import Step3 from './Signup.step3';
import Step4 from './Signup.step4';
import Step5 from './Signup.step5';
import Step6 from './Signup.step6';

import axios from 'axios';
import { Spinner } from 'views/Hoc/Spinner';

import { history } from '../../../_helpers';
import { useLocation } from 'react-router';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient(136deg, rgba(2,0,36,1) 0%, rgba(36,121,9,1) 27%, rgba(0,212,255,1) 100%)',  //stepper round color
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient(136deg, rgba(2,0,36,1) 0%, rgba(36,121,9,1) 27%, rgba(0,212,255,1) 100%)',  //stepper round color 
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;


  const icons = {
    1: <EmailIcon />,
    2: <PersonIcon />,
    3: <BusinessIcon />,
    4: <CallIcon />,
    5: <LockIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'green',                                         //for text description above the next and back button 
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const DefaultState = {
  fromOldChannel: false,
  step: 1,
  email: '',
  firstName: '',
  lastName: '',
  jobPosition: '',
  companyName: [],
  address: '',
  city: '',
  state: '',
  country: [],
  countryId: '',
  postalCode: '',
  websiteUrl: '',
  switchPhone: ['', '', ''],
  productFamilies: [],
  isOEM: '3',
  mobile: ['', '', ''],
  password: '',
  rePassword: '',
  channelTypeId: 0,
  hubspot_contact_id: null,
  hubspotCompanyId: null,
  oldChannelId: null,
  fromGuest: '',
  associateUser: false,
  signUpStatusId: null,
  channelId: null,
  userId: null,

  //events
  eventId: null,
  participantType: null,
  packageId: null,
  packages: [],
  selectedMarketingOptions: [],
  selectedPackageOptions: [],
  eventRegistration: false,
  eventRegisterAs: null,

  //newitems
  tweetPitch: '',
  // shortDesc: '',
  // companyLogo: '',


  errState: {
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    jobPosition: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    websiteUrl: '',
    password: '',
    rePassword: '',
    switchPhone: '',
    address: '',
    verified: 0,
    tweetPitch: '',
    // shortDesc: ''
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function CustomizedSteppers(props) {

  function getSteps() {
    if (eventId) {
      if (eventType != 2) {
        return ['Select Profile', 'Login Details', 'User Details', 'Company Details', 'Event Package', 'Verification Code'];
      }
      else {
        return ['Select Profile', 'Login Details', 'User Details', 'Company Details', 'Verification Code'];
      }
    }
    return ['Login Details', 'User Details', 'Company Details', 'Verification Code',];
  }

  let query = useQuery();

  //let eventId = query.get("eventId") == "122d6cc2-b33b-47f6-808a-fce568ad5d94" ? '3' : 0;

  let eventId = query.get("eventId");

  let eventType = query.get("eventType");

  let pType = query.get("pType");



  const { fromGuest } = props;

  const [eventDetail, setEventDetails] = useState({
    bannerSrc: null, imageSrc: null, description: null, buyerStatus: null, vendorStatus: null, venuetext: null
  });
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(eventId ? 0 : 1);
  const [Flag, setFlag] = React.useState(0);
  const steps = getSteps();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState('');
  const [isHubspot, setHubspot] = useState(false);
  const [state, setState] = React.useState(DefaultState);
  //console.log(state);

  const [packageList, setPackageList] = useState();

  const [staticValues, setStaticValues] = useState({
    productGroup: [],
    companyList: [],
    countryList: [],
  })

  //fromGuest Email Id setting

  useEffect(() => {
    if (state.channelTypeId != 0)
      getPackageDetails(state.channelTypeId, state.channelId);
  }, [state.channelTypeId])

  useEffect(() => {
    // if(fromGuest)
    // setState({...state, email: fromGuest});
    (async () => {
      if (eventId) {

        setState({ ...state, eventId: eventId, participantType: pType });
        await axios.get('/channelAdmin/getEventById/' + eventId).then(res => {
          if (res.data.length) {
            let event = res.data[0];
            // console.log("eventDetails:",event);
            setEventDetails({
              ...eventDetail, imageSrc: event.logo?.documentPath, bannerSrc: event.banner?.documentPath, description: event.description,
              buyerStatus: event.buyerStatus, vendorStatus: event.vendorStatus, venuetext: event.venuetext
            });

          }
        })

      }
    })();

  }, [])

  const getPackageDetails = async (type, channelId) => {
    setLoading(true);
    await axios.get('publicChannel/eventPackages/' + eventId + '/' + type + '/' + channelId).then(res => {
      setPackageList(res.data);//.filter(x => x.packageType == 1));

      setLoading(false);
      // ToastsStore.error('Something Went Wrong!');
      //   commonService.postService({err:err.response.data.message,reqUrl:window.location.pathname,service:'channel/eventPackages'},'/error')
    });



    // setData({...data});
    setLoading(false);

  }



  //used to handle form 1, 5(i mean 2)
  const assignValues = (data) => {

    if (fromGuest) {
      setFlag(1);
      state.email = fromGuest;
      setState({ ...state, ...data });
    } else {
      //console.log('assigner1', state, data);
      setState({ ...state, step: activeStep + 1, ...data });
      if (activeStep != 1)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const companyOldData = (companyName) => {
    const apiUrl = 'account/getCompanyData';

    if (companyName?.name) {
      axios.post(apiUrl, {
        name: companyName?.name
      }).then(res => {
        //console.log('responseformcompa', res.data);
        let temp = {};
        let btype;

        if (res.data) {

          state.isOEM = res.data.company.isOEM ? res.data.company.isOEM : state.isOEM;
          state.websiteUrl = res.data.company.channelDetail?.webSiteUrl;
          state.mobile[0] = res.data.company.channelDetail?.mobileNo ? res.data.company.channelDetail?.mobileNo.replace(/[^A-Z0-9]/ig, "") : '';
          state.mobile[1] = res.data.company.channelDetail?.mobileNo ? res.data.company.channelDetail?.mobileNo : '';
          state.switchPhone[0] = res.data.company.channelDetail?.phone1 ? res.data.company.channelDetail?.phone1.replace(/[^A-Z0-9]/ig, "") : '';
          state.switchPhone[1] = res.data.company.channelDetail?.phone1 ? res.data.company.channelDetail?.phone1 : '';

          if (res.data?.company?.regAddress) {
            let data = res.data.company.regAddress;

            if (data.city)
              temp['city'] = data.city;
            if (data.postalCode)
              temp['postalCode'] = data.postalCode;
            if (data.address)
              temp['address'] = data.address;
            if (data.countryId) {
              let c = staticValues.countryList.find(x => x.id == data.countryId)
              temp['country'] = [{ name: c.name, id: c.id }]
            }


          }
          if (res.data.product) {
            let val = staticValues.productGroup.filter(x => res.data.product.includes(+x.childid));
            state.productFamilies = val;

          }

        }
        // if (res.data.isOldChannel)
        //   setState({ ...state, regAddressId: res.data.company.regAddressId, oldChannelId: res.data.company.channelId });

        setState({ ...state, ...temp, regAddressId: res.data?.company?.regAddressId, oldChannelId: res.data?.company?.channelId, fromOldChannel: res.data?.isOldChannel, companyName: [companyName] });
      })

    }

  }

  const companyHubSpotData = async (company) => {
    const apiUrl = 'Hubspot/getCompanyWithId/' + company?.id;

    if (company?.id) {
      setHubspot(true);
      axios.get(apiUrl).then(async res => {
        //console.log('responseformcompa', res.data);
        if (res?.data?.properties) {
          let data = res.data.properties;
          if (data?.city)
            state.city = data.city?.value;
          if (data?.state)
            state.state = data.state?.value;
          if (data?.zip)
            state.postalCode = data.zip?.value;
          if (data?.address)
            state.address = data.address?.value;
          if (data?.country) {
            let c = staticValues.countryList.find(x => x.name.trim() == data.country?.value.trim());
            if (c)
              state.country = [{ name: c.name, id: c.id }]
          }
          if (data?.name)
            state.companyName = [{ name: data.name?.value, id: company?.id, type: 2 }];
          if (data?.job_position)
            state.jobPosition = data.job_position?.value;
          if (data?.website)
            state.websiteUrl = data.website.value;
          if (data?.phone) {
            state.switchPhone[0] = data.phone.value ? data.phone.value.split('/')[0].replace(/[^A-Z0-9]/ig, "") : '';
            state.switchPhone[1] = data.phone.value ? data.phone.value.split('/')[0] : '';
          }
          if (data.isoem) {
            state.isOEM = data.isoem.value;
            let prodFamily = await axios.get('/common/getProducts/' + state.isOEM);
            staticValues.productGroup = prodFamily.data.productGroup;
            setStaticValues({ ...staticValues });
            //   if(data.product_categorie){
            //     console.log('data.product_categorie.value', data.product_categorie.value);
            //     let cat = data.product_categorie.value.split(',');
            //     let val=staticValues.productGroup.filter(x=>cat.includes(x.childname));
            //     state.productFamilies= val;
            //     console.log('val',val);
            //   }
          }
          state.hubspotCompanyId = company?.id;
          setState({ ...state });
        }
        setHubspot(false);
      })
    }
  }

  //hubspot
  const getData = async () => {
    setLoad(true)
    let email = state.email;
    const apiUrl = 'Hubspot/getCompany/' + email;
    let details = {};
    await axios.get(apiUrl).then(async (res) => {

      if (res.data.company.length === 0) {
        //console.log('no data');
        if (res.data.contactVid) {


          details = {
            hubspot_contact_id: res.data.contactVid,
            jobPosition: res.data.properties.jobtitle ? res.data.properties.jobtitle.value : state.jobPosition,
            firstName: res.data.properties.firstname ? res.data.properties.firstname.value : state.firstName,
            lastName: res.data.properties.lastname ? res.data.properties.lastname.value : state.lastName,
          };

          if (res.data.properties.product_categorie) {
            // console.log('product_categorie', res.data.properties.product_categorie.value);
            let cat = res.data.properties.product_categorie.value.split(',');
            let val = staticValues.productGroup.filter(x => cat.includes(x.childname));
            state.productFamilies = val;
            // console.log('val', val);
          }

          console.log('hubspot contact without company associated');
        }
      }
      else {
        const apiUrl = 'account/getCompanyData';
        let response = await axios.post(apiUrl, {
          name: res.data?.company?.properties?.name?.value
        })
        if (response?.data?.company) {
          setLoad(false)
          return
        }
        let btype;
        if (res.data.properties?.b2b_b2c?.value == "B2B")
          btype = '2';
        else if (res.data.properties?.b2b_b2c?.value == "B2C")
          btype = '1';
        else
          btype = '3';

        details = {
          hubspot_contact_id: res.data.contactVid,
          hubspotCompanyId: res.data.company.companyId,
          companyName: [{ name: res.data.company.properties.name.value }],
          jobPosition: res.data.properties.jobtitle ? res.data.properties.jobtitle.value : state.jobPosition,
          city: res.data.company.properties.city ? res.data.company.properties.city.value : state.city,
          state: res.data?.company?.properties?.state ? res.data?.company?.properties?.state?.value : state.state,

          isOEM: btype,//res.data.company.properties.isoem ? res.data.company.properties.isoem.value : state.isOEM,
          address: res.data.company.properties.address ? res.data.company.properties.address.value : state.address,
          websiteUrl: res.data.company.properties.website ? res.data.company.properties.website.value.toLowerCase() : state.websiteUrl.toLowerCase(),
          postalCode: res.data.company.properties.zip ? res.data.company.properties.zip.value : state.postalCode,
          firstName: res.data.properties.firstname ? res.data.properties.firstname.value : state.firstName,
          lastName: res.data.properties.lastname ? res.data.properties.lastname.value : state.lastName
        };
        if (res.data.company.properties.country) {
          let c = staticValues.countryList.find(x => x.name.trim() == res.data.company.properties.country?.value.trim());
          if (c)
            state.country = [{ name: c.name, id: c.id }]
        }
        if (res.data.company?.properties?.phone) {
          state.switchPhone[0] = res.data.company.properties.phone.value ? res.data.company.properties.phone.value.split('/')[0].replace(/[^A-Z0-9]/ig, "") : '';
          state.switchPhone[1] = res.data.company.properties.phone.value ? res.data.company.properties.phone.value.split('/')[0] : '';
        }



        if (res.data.company.properties.product_categories) {
          //  console.log('product_categorie', res.data.company.properties.product_categories.value);
          let cat = res.data.company.properties.product_categories.value.split(';');
          let val = staticValues.productGroup.filter(x => cat.includes(x.childname));
          state.productFamilies = val;
          // console.log('val', val);
        }
      }
      setState({ ...state, ...details });
      setLoad(false)
      //console.log('getDArat', state, details);
    }).catch(error => {
      setLoad(false)
      console.log("error", error, state)
    });
  }

  //used to fetch data for email if already exists in old table or hubspot table
  useEffect(() => {
    (async () => {
      if (Flag) {
        let response = await gettingData();
        //console.log('response', response);
        if (response === 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else if (response === 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        //console.log('getting data');
      }
      setFlag(1);
    })();
  }, [state.email, state.fromGuest])


  //FinishSubmit
  const finishSubmit = async () => {

    // const prodFam = [];

    //   if(state.productValue.length > 0){
    //     state.productValue.map(x => {
    //       prodFam.push( +x.groupid);
    //     });
    //   }
    //console.log('prod', prodFam)  


    let data = {
      firstName: state.firstName,
      lastName: state.lastName,
      companyName: state.companyName[0].name,
      jobPosition: state.jobPosition,
      email: state.email,
      password: state.password,
      channelTypeId: +state.channelTypeId == 0 ? 6 : state.channelTypeId,
      oldChannelId: state.companyName[0].type == 1 ? state.companyName[0].id : null,
      hubspot_contact_id: state.hubspot_contact_id,
      hubspotCompanyId: state.hubspotCompanyId,
      mobile: state.mobile[1],
      switchPhone: state.switchPhone[1],

      //removing address and add company description and logo
      //address: state.address,
      //shortDesc: state.shortDesc,
      tweetPitch: state.tweetPitch,
      //companyLogo: state.companyLogo,

      country: state.country[0].name,
      countryId: parseInt(state.country[0].id),
      postalCode: state.postalCode,
      city: state.city,
      ...(state.state && { state: state.state }),
      websiteurl: state.websiteUrl,
      productFamilies: state.productFamilies.map(x => +x.groupid), // prodFam,
      productCategories: state.productFamilies.map(x => +x.childid),
      productFamily: [...new Set(state.productFamilies.map(x => x.groupname))],
      productCat: state.productFamilies.map(x => x.childname),
      isOEM: state.isOEM,

      //events
      eventId: state.eventId,
      participantType: state.channelTypeId == '2' ? 1 : 2,
      marketingOptions: state.selectedMarketingOptions?.length ? state.selectedMarketingOptions.map(x => +x.optionId) : [],
      packageOptions: state.selectedPackageOptions?.length ? state.selectedPackageOptions.map(x => +x.optionId) : [],
      packageId: state.packageId,
      eventName: '',
      eventRegisterAs: state.eventRegisterAs,

      fromOldChannel: state.fromOldChannel
    }

    if (state.fromOldChannel) {
      data = { ...data, regAddressId: state.regAddressId, oldChannelId: state.oldChannelId }
    }

    //formatting data for sending to pending approval mail
    let prodFam = [...new Set(state.productFamilies.map(x => x.groupname))];
    let temp = '';
    prodFam.map(x => temp += x + ' ,');
    let formattedProdFam = temp;
    formattedProdFam = formattedProdFam.slice(0, formattedProdFam.length - 1);

    temp = '';
    let prodCat = state.productFamilies.map(x => x.childname);
    prodCat.map(x => temp += x + ' ,');
    let formattedProdCat = temp;
    formattedProdCat = formattedProdCat.slice(0, formattedProdCat.length - 1);
    const datamail = {
      "First Name": state.firstName,
      "Last Name": state.lastName,
      "Company Name": state.companyName[0].name,
      "Job Position": state.jobPosition,
      Mobile: state.mobile[1],
     // "Switch Phone": state.switchPhone[1],
     // Address: state.address,
     // "Postal Code": state.postalCode,
     // City: state.city,
     // State: state.state,
      Email: state.email,
      "Website url": state.websiteUrl,
      "Business Type": state.isOEM == '1' ? 'B2C' : state.isOEM == '2' ? 'B2B' : 'B2B, B2C',
     // "Product Families": formattedProdFam,
     // "Product Categories": formattedProdCat,
    }

   // console.log("newfields:", data);
   // console.log("data mail:", datamail);

    setLoad(true)
    axios.post('account', data).then(res => {
      //console.log('res', res);
      if (res.status == 201) {
        // axios.post('/emailer/', {
        //   type: 'signup_bo',
        //   data: {
        //   title: 'Confirmation Mail',
        //   email: state.email,
        //   companyName: state.companyName[0].name
        // }
        // });

        //hide for GRC
        axios.post('mailerservice/Pending_Approval_Mail', {
          email: state.email,
          to: 'info@channelhub.net',
          userData: datamail,
          eventId: state.eventId ? state.eventId : null
        }
        );
        if (state.eventId) {
          let eventData = {
            name: state.firstName + ' ' + state.lastName,
            packageId: state.packageId,
            eventId: state.eventId,
            email: state.email,
            profileurl: process.env.REACT_APP_LOCALSITE + 'login'
          }

          axios.post('mailerservice/eventSignupMail', eventData);
        } else {
          axios.post('mailerservice/After_Signup_Mail', {
            name: state.firstName,
            to: state.email,
            eventId: state.eventId ? state.eventId : null
          })
        }

        // hide for GRC
        // axios.post('mailerservice/After_Signup_Mail', {
        //   name: state.firstName,
        //   to: state.email,
        //   eventId: state.eventId ? state.eventId : null
        // }
        // );

        setLoad(false);
        history.push({ pathname: '/Pending', state: { eventId: eventId ? eventId : '' } });
      }
    }).catch(err => {
      console.log('err', err);
    })


  }

  //associate user finish submit
  const associateUserSubmit = async () => {
    setLoad(true)
    let contact = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      isActive: '0',
      isDefaultContact: state.signUpStatusId == 16 ? '1' : '0',
      jobTitle: state.jobPosition,

      mobileNo: state?.mobile[1],

      password: state.password,
      // workNatureId: 1,

    };

    const data = {
      signUpStatusId: state.signUpStatusId,
      channelId: state.channelId,
      userId: state.userId,
      contact: contact,
      channelTypeId: +state.channelTypeId,
      productFamilies: [],//state.productFamilies.map(x => +x.groupid), // prodFam,
      productCategories: [],// state.productFamilies.map(x => +x.childid),
      productFamily: [...new Set(state.productFamilies.map(x => x.groupname))],
      productCat: state.productFamilies.map(x => x.childname),


      //events
      eventId: state.packageId ? state.eventId : null,
      participantType: state.channelTypeId == '2' ? 1 : 2,
      marketingOptions: state.selectedMarketingOptions?.length ? state.selectedMarketingOptions.map(x => +x.optionId) : [],
      packageOptions: state.selectedPackageOptions?.length ? state.selectedPackageOptions.map(x => +x.optionId) : [],
      packageId: state.packageId,
      eventName: '',
    }

    await axios.post('account/addSignupUser', data).then(res => {
      setLoad(false);

      if (res.status == 201) {
        // axios.post('mailerservice/After_Signup_Mail', {
        //   name: state.firstName,
        //   to: state.email,
        //   eventId: state.eventId ? state.eventId : null
        // })

        if (state.eventId && state.packageId) {
          let eventData = {
            name: state.firstName + ' ' + state.lastName,
            packageId: state.packageId,
            eventId: state.eventId,
            email: state.email,
            profileurl: process.env.REACT_APP_LOCALSITE + 'login'
          }

          axios.post('mailerservice/eventSignupMail', eventData);
        }

        axios.post('mailerservice/Pending_Approval_Mail_AssociateUser', {
          email: state.email,
          to: 'info@channelhub.net',
          name: state.firstName + ' ' + state.lastName,
          companyName: state.companyName[0].name,
          eventId: state.eventId ? state.eventId : null
        }
        );
      }

      history.push({ pathname: '/Pending', state: { eventId: eventId ? eventId : '' } });
    })

  }

  // const gotopending = () => {
  //   history.push({pathname: '/Pending', state: {eventId: eventId ? eventId : ''}});
  // }

  const gettingData = async () => {
    let res = await axios.post('/common/getOldProduct', {
      email: state.email
    }).then(res => {
      //console.log("products:", res.data.companyAddress.length);
      //let fam=[];
      //fam.push(res.data.productGroup);
      state.productFamilies = res.data.productGroup;
      // setState({...state,productFamilies:state.productFamilies});
      //if(res.data.companyAddress.length>0)
      setState(
        {
          ...state, productFamilies: state.productFamilies,
          companyName: res.data.companyAddress.length > 0 ? [{ name: res.data.companyAddress[0].name, id: res.data.companyAddress[0].id }] : state.companyName,
          jobPosition: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].jobTitle : state.jobPosition,
          firstName: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].firstName : state.firstName,
          lastName: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].lastName : state.lastName,
          address: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].address : state.address,
          city: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].city : state.city,
          country: res.data.companyAddress.length > 0 ? [{ name: res.data.companyAddress[0].country, id: res.data.companyAddress[0].countryId }] : state.country,
          websiteUrl: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].webSiteUrl : state.websiteUrl,
          postalCode: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].postalCode : state.postalCode,
          countryId: res.data.companyAddress.length > 0 ? res.data.companyAddress[0].isoCode : state.countryId,
        });
      return 1;
    });
    await getData();
    //console.log('afterget', state);
    return res;
  }

  //used to handle forms 2, 3, 4
  const handleNext = async () => {
  //  console.log('handlenexts', activeStep);

    if (state.associateUser && activeStep == 2)
      setActiveStep(4);
    else
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setState({ ...state, step: activeStep });


  };

  const getSignup = async (channelTypeId) => {
    const apiUrl = '/common/getSignup/' + channelTypeId;
    axios.get(apiUrl).then(res => {
      //console.log("pgrrou:",res.data.productGroup);
      if (channelTypeId != '5')
        staticValues.productGroup = res.data.productGroup;
      staticValues.companyList = res.data.company;
      staticValues.countryList = res.data.country;
      setStaticValues({ ...staticValues });
      // getHubspot(res.data.company);

      if (channelTypeId == '5' || channelTypeId == '4')
        getProducts(state.isOEM);
    });

    if (eventId) {
      await axios.get('/channelAdmin/getEventById/' + eventId).then(res => {
        let eventDet = res.data[0];

        if (eventDet) {
          //console.log('event details', eventDet);
          state.packages = eventDet.packages?.filter(x => x.channelType == 3);
          setState({ ...state, channelTypeId: channelTypeId, eventName: eventDet?.name });
        }

      });
    }

  }

  const getNewSignup = async () => {

    const apiUrl = '/common/getNewSignup/';
    axios.get(apiUrl).then(res => {
      staticValues.companyList = res.data.company;
      staticValues.countryList = res.data.country;
      setStaticValues({ ...staticValues });
    });

  }


  const getHubspot = (data) => {
    const apiUrl = '/Hubspot/getCompanyList';
    axios.get(apiUrl).then(res => {
      let list = data;
      list.push(...res.data);
      staticValues.companyList = list;
      setStaticValues({ ...staticValues });
    });
  }

  const getProducts = (businessType) => {
    const apiUrl = '/common/getProducts/' + businessType;
    axios.get(apiUrl).then(res => {
      //console.log("pgrrou:",res.data.productGroup);
      staticValues.productGroup = res.data.productGroup;
      setStaticValues({ ...staticValues });
    });
  }

  const signUp = (otpSuccess) => {
    //handleNext();

    //console.log("otpsuccess:", otpSuccess);
    if (otpSuccess) {
      //console.log('hi');
      setState({ ...state, verified: 1 });
    }
  }

  const handleBack = () => {
    setState({
      ...state, errState: {
        //to b
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        jobPosition: '',
        password: '',
        rePassword: '',

        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        websiteUrl: '',
        switchPhone: '',
        productFamilies: '',
      }
    });

    if (state.associateUser && activeStep == 4)
      setActiveStep(2);
    else
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = input => e => {
    if (input == 'isOEM') {
      getProducts(e.target.value);
      setState({ ...state, [input]: e.target.value, productFamilies: [] });
    } else {
      setState({ ...state, [input]: e.target.value });
    }


  }

  const handleLogo = (input, url) => {
   // console.log("companyLogo:", input, url);
    setState({ ...state, [input]: url });
  }


  const handleChangeAddress = (input, value, faddress) => {
    setLoading(faddress);
    //console.log(faddress)
    const gAddress = {
      address: faddress,
      city: '',
      state: '',
      country: '',
      postalCode: '',
      countryId: ''
    }
    value.map(x => {
      if (x.types.length && (x.types.includes('locality') || x.types.includes('postal_town'))) {
        gAddress.city = x.long_name;
        faddress = faddress.replace(gAddress.city + ', ', '');
      }
      if (x.types.length && x.types.includes("administrative_area_level_1")) {
        gAddress.state = x.long_name;
        faddress = faddress.replace(gAddress.state + ', ', '');
      }
      if (x.types.length && x.types.includes("country")) {
        let id = '';
        let name = ''
        id = staticValues.countryList.find(a => a.countryCode === x.short_name.substring(0, 2)).id;
        name = staticValues.countryList.find(a => a.countryCode === x.short_name.substring(0, 2)).name;
        //console.log('cid', id)
        gAddress.country = [{ name: name, countryCode: x.short_name, id: id }]; //
        gAddress.countryId = x.short_name;
        faddress = faddress.replace(name, '');
      }
      if (x.types.length && x.types.includes("postal_code"))
        gAddress.postalCode = x.long_name;
    })

    gAddress.address = faddress.substring(faddress.length - 2,) == ', ' ? faddress.slice(0, faddress.length - 2) : faddress;

    setState({ ...state, ...gAddress });

  }

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:", check, gname);
    if (check == true) {
      staticValues[optionName].map((item) => {
        //console.log("groupname:", item.groupname);
        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      //console.log("check:", check);
      staticValues[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setStaticValues({ ...staticValues, [optionName]: staticValues[optionName] });
  }


  const handleChangeSelect = async (input, value) => {
    // console.log("values:", value);

    if (input == 'productFamilies') {
      staticValues.productGroup.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!=item.childname)
          //     item.status=false;                 
          //   else if(v.childname==item.childname)            
          //     item.status=true;                       

          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productFamilies = value
      setStaticValues({ ...staticValues, productGroup: staticValues.productGroup });
      setState({ ...state, productFamilies: state.productFamilies });
      // state.productFamilies=value;
      // setState({...state, productFamilies:state.productFamilies});
      //console.log("famvalue:",state.productFamilies);
    }
    else if (input == 'channelTypeId' || input == 'mobile' || input == 'switchPhone' || input == 'address' || input == 'verified' || input == 'packageId' || input == 'associateUser') {
      setState({ ...state, [input]: value });
    }
    else if (input == 'companyName') {
      //console.log('companyName', value)
      if (value?.type === 1)
        await companyOldData(value);
      else if (value?.type === 2)
        companyHubSpotData(value);
      else {
        setHubspot(true);
        state.hubspotCompanyId = null;
        state.city = null;
        state.state = null;
        state.postalCode = null;
        state.address = null;
        state.country = [];
        // state.jobPosition = '';
        state.websiteUrl = '';
        state.switchPhone = ['', '', ''];
        state.isOEM = '3';
        setState({ ...state });
        setHubspot(false);
      }
      setState({ ...state, [input]: [value] });
    }
    else if (input == 'selectedPackageOptions' || input == 'selectedMarketingOptions') {
      setState({ ...state, [input]: value })
    }
    else if (input == 'signUpUser') {

      if (value.eventRegistration) {
        getPackageDetails(state.channelTypeId, value.channelId);
      }
      state.associateUser = true;
      state.signUpStatusId = value.signUpStatusId;
      state.channelId = value.channelId;
      state.userId = value.userId;
      state.eventRegistration = value.eventRegistration;
      setState({ ...state });
    }
    else {
      setState({ ...state, [input]: [value] });
    }
  }

  function getStepContent(step) {

    if (eventType == 2 && step == 4)
      step = 5;

    switch (step) {
      case 0:
        return <Step1 handleChangeSelect={handleChangeSelect} values={{ ...state, participantType: pType }} assignValues={assignValues} getSignup={getSignup} />;
      case 1:
        return <Step5 handleChange={handleChange} getNewSignup={getNewSignup} handleNext={handleNext} handleChangeSelect={handleChangeSelect} values={state} assignValues={assignValues} />;
      case 2:
        return isHubspot ? <Spinner /> : <Step2 handleChange={handleChange} selectedItem={selectedItem} handleChangeSelect={handleChangeSelect} values={state} handleNext={handleNext} staticValues={staticValues} />;
      case 3:
        return <Step3 handleLogo={handleLogo} handleChangeAddress={handleChangeAddress} handleChangeSelect={handleChangeSelect} handleChange={handleChange} values={state} handleNext={handleNext} staticValues={staticValues} loading={loading} />;
      case 4:
        return eventId ? <Step6 handleChangeSelect={handleChangeSelect} values={state} assignValues={assignValues} packageList={packageList} /> : <Step4 signUp={signUp} handleChangeSelect={handleChangeSelect} values={state} handleNext={handleNext} assignValues={assignValues} staticValues={staticValues} />;
      case 5:
        return <Step4 signUp={signUp} handleChangeSelect={handleChangeSelect} values={state} handleNext={handleNext} assignValues={assignValues} staticValues={staticValues} />;

      default:
        return 'Unknown step';
    }
  }

  return (
    <div class="main-content">
      {load && <Spinner />}
      <div className="head-logo" style={eventId ? { height: "13rem" } : {}} >
        {eventId ?
          eventDetail?.imageSrc ?
            <Link to="/" >
              <img alt="event-logo" src={process.env.REACT_APP_CDN_URL + eventDetail.imageSrc} style={eventId ? { width: "150px", height: "150px" } : {}} />
            </Link>
            : '' //<Spinner />
          :
          <Link to="/" >
            <img alt="ch-logo" src={require('assets/img/brand/ch-logo.svg')} />
          </Link>
        }
        {eventId && eventDetail.venuetext &&
          <h2 className='text-center mt-2'>{eventDetail.venuetext}</h2>}


        {/* 
        {eventId == 5 && <h2 className='text-center mt-2'>19-21 October - Online event</h2>} */}
      </div>
      {eventId ?
        <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "500px", backgroundImage: "url(" + process.env.REACT_APP_CDN_URL + eventDetail.bannerSrc + ")" }} class={`header mt-1   signuptophead pt-lg-6`}>
          <div className='card-overlay'></div>
          <div class="container-lg">
            <div class="header-body text-center" style={{ marginTop: "-4rem", marginBottom: '2.25rem' }}>
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12 px-5">
                  {/* <h1 class="text-white">{eventId ? `Application form ${eventDetail.vendorStatus == 1 ? 'Vendor' : eventDetail.buyerStatus == 1 ? 'Buyer' : ''}` : "Create Your Account"}</h1> */}
                  <h1 class="text-white">{eventId ? "Application form" : "Create Your Account"}</h1>
                  <p class="text-lead text-white">{(eventId && eventDetail.description) ? eventDetail.description : "You are only few minutes away from joining the largest community of CE and IT professionals in the World."}</p>
                </div>
              </div>
            </div>
          </div>
          {eventId ? <></> :
            <div class="separator separator-bottom separator-skew zindex-100">
              <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>}
        </div>
        :
        <div style={{ height: "410px" }} class={`header ${eventId ? 'signup-header-bg1' : 'signup-header-bg'} mt-1   signuptophead signcontainer pt-lg-6`}>

          <div class="container-lg">
            <div class="header-body text-center" style={{ marginTop: "-4rem", marginBottom: '2.25rem' }}>
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-8 px-5">
                  {/* <h1 class="text-white">{eventId ? `Application form ${eventDetail.vendorStatus == 1 ? 'Vendor' : eventDetail.buyerStatus == 1 ? 'Buyer' : ''}` : "Create Your Account"}</h1> */}
                  <h1 class="text-white">{eventId ? "Application form" : "Create Your Account"}</h1>

                  <p class="text-lead text-white">{(eventId && eventDetail.description) ? eventDetail.description : "You are only few minutes away from joining the largest community of CE and IT professionals in the World."}</p>


                </div>
              </div>
            </div>
          </div>
          {eventId ? <></> :
            <div class="separator separator-bottom separator-skew zindex-100">
              <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>}
        </div>}


      <div class="container signupcontainer pb-5">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-12" style={{ padding: 0 }}>
            <div class="card bg-secondary border-0 mb-0">

              <div class="card-body px-lg-5 py-lg-5">
                <div className={classes.root}>
                  <Stepper style={{ backgroundColor: '#f7fafc' }} activeStep={eventId ? activeStep : activeStep - 1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {(eventId && activeStep === steps.length && activeStep === steps.length + 1) ? (
                      <div className="reset-but">
                        <Typography className={classes.instructions}>Thank you for your application.Our team will review your profile and comeback to you shortly.</Typography>
                        {/* <button className="btn btn-warning ch-btn-blue reset-but" onClick={handleReset}>Reset</button> */}
                      </div>
                    ) : (
                      <div>
                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                        <div>
                          {state.verified || activeStep == 2 || (activeStep == 4 && !eventId) ? '' : (<button
                            hidden={activeStep === 0}
                            onClick={handleBack}
                            className="btn btn-primary back-but"
                          >
                            Back
                          </button>)}



                          {
                            (eventId ? activeStep === steps.length - 1 : activeStep - 1 === steps.length - 1) ?
                              (state.verified ? (<button className="btn btn-primary ch-btn-blue1 right-but" variant="contained" color="primary" onClick={state.associateUser ? associateUserSubmit : finishSubmit}>
                                Finish
                              </button>) : '') : ''
                          }
                          {/*: (<button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={validateNext}>
                Next
              </button>)
                } */}
                          {/* <button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={activeStep === steps.length - 1 ? '' : validateNext}>
                {//activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={() => gotopending()}>goto pending</button> */}
    </div>
  );
}
const data1 = [{ id: "1", name: "AV" }, { id: "2", name: "AB" }];
