import React, { useState, useEffect } from 'react';
//import { Row, Col, Card, Button } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar'
import axios from 'axios';
import Slider from "react-slick";
// import CardBody from 'reactstrap/lib/CardBody';
// import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import DateCountdown from 'react-date-countdown-timer';
import { history } from '_helpers/history';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import ShareIcon from '@mui/icons-material/Share';
import Moment from 'moment';
import { useDispatch } from "react-redux";
import { eventService } from "_services";
import { ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import EditIcon from "@mui/icons-material/Edit";
import * as htmlToImage from 'html-to-image';
import { ModalSpinner, Spinner } from 'views/Hoc/Spinner';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";

import {
  Row, Col,
  Container,
  Modal, ModalFooter,
  ModalHeader, ModalBody,
  CardBody, Card, Nav, Button, NavItem, NavLink, UncontrolledTooltip,
} from "reactstrap";
import Userprofileform from '../profile/Settings/Userprofileform';

var _ = require('lodash');


const Dashslider = ({ products }) => {
  const [slide, setSlide] = useState(0);


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => setSlide(current)
  };
  const productDetailView = (externalURL) => {
   if(externalURL != null){
     const win = window.open(externalURL);
     win.focus();
   }
 }
  return (
    <div className="ProductContainer p-3" style={{ backgroundColor: "#f8f9fe" }} id='dashslide'>
      <Slider {...settings}>

        {products.map(row => (

          <div className="d-flex justify-content-left publicproduct">

            {row.map(x => (
              <Col xl='3' lg='4' md='6' sm='12' xs='12'>
                <div id="dictnSKU00011801566" className="ListContainer" style={{cursor: 'pointer'}} onClick={() => {productDetailView(x.externalURL)}}>
                  
                    <div className='list-image' >
                      <img class="listcontainheight" src={x.image ? process.env.REACT_APP_CDN_URL + x.image : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />
                    </div>
                    <h3>{x.product}</h3>
                    <p class="dictor">{x.brandName}</p>
                  
                </div>
              </Col>
            ))}
          </div>
        ))}


      </Slider>
    </div>
  );
};
const DashVenslider = ({ channels, upgrade, homepage=false}) => {
  let count = 0;
  const [slide, setSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => setSlide(current)
  };


  return (
    <div className="ProductContainer p-3" style={{ backgroundColor: "#f8f9fe" }} id='dashslide'>
      <Slider {...settings}>

        {channels.map(row => (
          <div className="d-flex justify-content-left publicproduct">




            {row.map(x => (
              // <Col xl='3' lg='4' md='6' sm='12'>
              //   <Card className='m-0'>
              //     <div className='p-2 ListContainer' style={{ borderRadius: "10px" }}>
              //       <div className='pt-4 late-buyers list-image' >
              //         <a href="" onClick={e => { e.preventDefault(); upgrade(x.channelId) }}>
              //           <img
              //             alt="..."
              //             className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
              //             src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
              //             // style={{ width: "180px" }}
              //           />
              //           {/* <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }}/> */}
              //         </a>
              //       </div>
              //       <div className="pr-2 pl-2 mt-2 text-center">
              //         {/* id={"dictn1"+x.productSKU} */}
              //         <h3>{x.companyName}</h3>
              //         <p>{x.country}</p>
              //       </div>
              //     </div>
              //   </Card>
              // </Col>
              <Col xl='3' lg='4' md='6' sm='12' xs='12'>
                <div id="dictnSKU00011801566" className="ListContainer" style={{ borderRadius: "10px" }}>
                  <a href="" onClick={e => { 
                     e.preventDefault();  
                     if(homepage){
                      let win = window.open(`/channelDetailView/${x.channelId}`);
                      win.focus();
                     }else{
                      upgrade(x.channelId)
                     }
                   }}>
                    <div className='list-image'>
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                      // style={{ width: "180px" }}
                      />  </div>
                    <h3>{x.companyName}</h3>
                    <p>{x.country}</p>
                  </a>
                </div>
              </Col>
            ))}
          </div>
        ))}



      </Slider>
    </div>
  );
};
const DashEventlist = ({ eventData }) => {
    let [sharemodal, setshareModal] = useState(false);

    const[profileModal_isOpen, setProfileModal_isOpen] = useState(false);
    
    let [isModalPopup, setModalPopup]  = useState(false);
    const[popupMessage, setPopupMessage] = useState(null);
 
    const confirmPopup = (res) => {
      setProfileModal_isOpen(true);
      setModalPopup(false);
      setshareModal(false);
    }

    const closePopup = () => {
        setModalPopup(false);
        setshareModal(false);
    };

    const getJobTitle = async()=>{
      try {
        var userData = JSON.parse(localStorage.getItem('CHuser'));
        let result = await axios.get(`user/getJobTitle/${userData.contactId}`);
        setJobTitle(result.data.jobTitle)
      } catch (error) {
        console.log(error);
      }
    }

    const DashsliderFunction = async()=>{
      setProfileModal_isOpen(false);
      var userData = JSON.parse(localStorage.getItem('CHuser'));
      if(userData.documentUrl != null){
        setModalPopup(false);
        setshareModal(true);
      }
      getJobTitle();
    }

  
 
  let [shareData, setShareData] = useState({ shortname: null, companyName: null, companyLogo: null });

  const Linkedin_ClientId = '866eaqbozn09m4'; //process.env.REACT_LINKEDIN_CLIENTID
  // const Linkedin_RedirectUri = 'http://localhost:4200/admin/linkedinconnect';
  const Linkedin_RedirectUri = process.env.REACT_APP_LOCALSITE + 'admin/linkedinconnect';

  const [showMsg, setMsg] = React.useState(false);
  const [errMsg, setErrorMsg] = React.useState(false);

  const [dueAlert, setDueAlert] = useState({ visible: false, item: null, totalSplits: null });

  var userData = JSON.parse(localStorage.getItem('CHuser'));

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [slide, setSlide] = useState(0);
  let [latestEvent, setEventData] = useState(eventData);

  let [timerFlag, setTimerFlag] = useState(false);

  let [profileData, setProfileData] = useState(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 2000,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => setSlide(current)
  };

  let [isBoFo, setIsBoFo] = useState(false);
  const [list, setList] = useState([]);
  let [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [companyLogoFlag, setCompanyLogoFlag] = useState(false);
  const [contactLogoFlag, setContactLogoFlag] = React.useState(false);
  const [tempmodal, settempModal] = useState(false);
  const[socialImage, setSocialImage] = useState(null);
  const[popupImage, setPopupImage] = useState(null);
  const [linkedData, setlinkedData] = useState({ firstName: '', lastName: '', companyName: '', companyLogo: '', contactLogo: '' });
  const[jobTitle, setJobTitle] = useState(null);
  useEffect(() => {

    isBoFo = window.location.hostname === process.env.REACT_APP_BOFO;
    setIsBoFo(isBoFo);

    getEventList();

  }, []);

  const getEventList = async () => {
    const url = 'getEventRegister';
    var userData = JSON.parse(localStorage.getItem('CHuser'));

    let data = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration/' + userData.contactId
    }
    // commonService.postService(data,'account/getEventRegister').then(res =>{
    //   setLoading(false);
    //   list = listData = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
    //   setListData(listData);
    //   setList(list);
    //   console.log('list', list)
    //   // resultCount= list?list.length:0;
    // }, err=>{
    //   setLoading(false);
    //   console.log('err', err)
    // });

    let result = await axios.get('/channel/getEventListing').then(res => {
      setLoading(false);
      listData = _.orderBy(res.data.registrations, ['inFrontPriority'], ['asc']);
      setListData(listData);
      // let latestEvent = list.filter(x => x.channel && x.channel['channel'].contacts.length && x.channel['channel'].contacts[0].eventParticipants.length > 0 && x.eventStatus != 3)
      let latestEvent = listData.filter(x => x.inFront === '1')


      if (latestEvent.length > 0) {
        // setList([latestEvent[0]])
        setList(latestEvent);
        let flag = false
        
       

        latestEvent.forEach(item => {
          let participant = item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants.find(p => p.eventId == item.eventId);

      
          if (participant && (participant?.shareStatus === '0' || participant?.shareStatus == null)) {

            let companyName = item.channel?.channel?.companyName;

            let companyLogo = item.channel?.channel?.['channelDetail'].channelLogo?.documentPath;

            let userData = JSON.parse(localStorage.getItem('CHuser'));
            const tblContact = item.channel?.channel?.contacts?.find(contact => contact.contactId === userData.contactId);
            const jobTitle = tblContact ? tblContact.jobTitle : null;
            setJobTitle(jobTitle);

            shareData = { eventId: item.eventId, shortname: item.shortname, companyName: companyName, companyLogo: companyLogo};
            setShareData(shareData);
    
            flag = true;
            return;
          }
        });


        // eventData = eventData.filter(x => x.id == latestEvent[0].eventId);
        // setEventData([eventData[0]]);
        setTimerFlag(true);
        if (flag) {
          sharemodal = false;
          setshareModal(sharemodal);
        }
      }
      // else {
      //   setTimerFlag(true);
      //   let latestEvent = list.filter(x => x.eventId == eventData[0].id);
      //   setList([latestEvent[0]]);
      //   console.log("latestevent:", latestEvent)
      //   // console.log("recentevent:", eventData[0],list);
      // }

      // console.log('list', list)
    });
  }



  const selectEvent = (item) => {
    let dashboard = userData?.channelTypeId == 2 ? 'vendorDashboard' : 'BuyerDashboard';
    //console.log("spack:", item);

    let NO_SEATS_FLAG = true; let regProcess; let completedPackage = [];
    for (let user = 1; user <= 2; user++) {
      for (let pack = 1; pack <= 2; pack++) {
        let currentPack = item.packages.filter(x => x.packageUser == user && x.packageType == pack)
        if (currentPack.length) {
          if (currentPack[0].registeredCount == 0) {
            NO_SEATS_FLAG = false;
            regProcess = 1;
            //move to subscription page with package selection
          }
          else if (currentPack[0].registeredCount > 0 && currentPack[0].registeredCount < currentPack[0].accessBadge) {
            NO_SEATS_FLAG = false;
            regProcess = 2;
            //register user as a delegate 
          }
          if (currentPack[0].accessBadge == currentPack[0].registeredCount) {
            completedPackage.push(currentPack[0].packageId);
          }
        }

        //move to next package of first user and repeat the same process
      }
      //move to second user and repeat the same process    
    }

    dispatch({ type: 'EVENT', data: { ...item, completedPackage: completedPackage } })

    if (item.type == 2)
      digitalEventRegister(item)
    else {
      if (regProcess == 2)// item.hasPrimaryPackage == '1' && item.alreadyRegistered < item.accessBadge)
        digitalEventRegister(item)
      else if (regProcess == 1)//item.hasSecondaryPackage == '1' && item.alreadyRegistered < item.accessBadge)
        history.push('/admin/' + dashboard + '/eventSubscription/' + item.eventId);
      else
        history.push('/admin/' + dashboard + '/eventSubscription/' + item.eventId);
      //setErrorMsg(true);

    }
  }

  const digitalEventRegister = (item) => {

    let userData = JSON.parse(localStorage.getItem('CHuser'));

    let eventDetails = {
      eventId: item.eventId,
      packageId: item.packageId,
      regTemplate: item.regTemplate,
    };

    dispatch({ type: 'EVENT_USERS', data: { contacts: [userData.contactId] } });
    dispatch({ type: 'EVENT_PACK', data: { ...eventDetails } });


    let payment = {
      paymentStatus: '1',
      paymentMethod: null,
      paymentTransactionId: null,
      isFromFO: 1,
      eventType: item.type,
      isDelegate: true
    };

    eventService.eventRegistration(payment);

  }

  const eventRegister = () => {
    var userData = JSON.parse(localStorage.getItem('CHuser'));
    return new Promise((resolve) => {
      commonService.getServices('channel/eventRegister/' + userData.contactId).then(res => {
        profileData = res.data;
        setProfileData(profileData)
        console.log('res.data', res.data);
        resolve(true);
      }, err => {
        resolve(false);
        console.log('err', err);
      });
    })
  }

  const getToken = () => {
    let req = {
      url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
    }
    return commonService.postService(req, 'account/eventcatalystToken').then(res => {
      if (res.data?.error) {
        setLoading(false);
        // ToastsStore.warning('Something went wrong'); 
      } else {
        localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
      }
      return JSON.stringify(res.data)
    }).catch(err => {
      setLoading(false);
      console.log('err', err)
    })
  }

  const getManage = async (event) => {
    var userData = JSON.parse(localStorage.getItem('CHuser'));

    // if (event.eventId == 6)
    //   setMsg(true);
    // else {

    setLoading(true);
    if (!localStorage.getItem('eventcatalyst_token')) {
      await getToken();
    }
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    if ((dec === null) || (dec === '') || !dec) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }
    let data = {
      participantId: parseInt(userData.contactId),
      eventId: event.event_catalyst_id,
      companyId: parseInt(userData.channelId),
      existingCategory: profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      interestedCategory: profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      profileType: profileData.length > 0 ? profileData[0].ProfileType : null,
      retailCustomerProfile: profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|") : null : null,
      detailDesc: dec,
      password: profileData.length > 0 ? profileData[0].password : null
    }

    let body = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/manage',
      body: data
    }
    commonService.postService(body, 'account/postEventRegister').then(res => {
      setLoading(false);
      window.open(res.data.pageUrl, '_blank');
    });

    // }



  }

  const displayMsg = (value) => {
    setMsg(false);
    setErrorMsg(false);
  }

  const closeMsg = () => {
    setMsg(false);
    setErrorMsg(false);
  }
  const sharetoggle = (eventId, shortname, companyName, companyLogo) => {
    // console.log("shortname:",shortname,companyName)
    localStorage.setItem("Linked_Share_EventId", eventId);
    const data = { shortname: shortname, companyName: companyName, companyLogo: companyLogo };
    setShareData(data);
    setshareModal(!sharemodal);

  }

  const duesAlert = (item, totalSplits, vatPercentage) => {

    const setManage = () => {

      setDueAlert({ ...dueAlert, visible: false });
      let pack = item.channel?.package;

      let packDetails = {};
      if (item.type == 1 && pack) {
        packDetails = {
          eventId: item.eventId,
          packageId: pack.packageId,
          price: pack.price,
          additionalMembersCosting: pack.additionalMembersCosting,
          membersAllowed: pack.no_of_access_badge,
          packageName: pack.packageName,
          isSeller: pack.isSeller,
          isBuyer: pack.isBuyer,
          additionalMembers: item.channel?.additionalMembers ? item.channel?.additionalMembers : 0,
          marketingOptions: pack.marketingOptions,
          packageOptions: pack.packageOptions
        };
      }


      dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });
      dispatch({ type: 'EVENT', data: { ...item } });
      if (item.type == 2)
        history.push('events/buyersession/' + item.eventId);
      else if (item.type == 1)// && !pack))
        history.push('/admin/events/eventManagement/' + item.eventId);
      else
        history.push('/admin/events/eventManage');

    }

    return (
      <div>
        <Modal className="modal-dialog-centered paymentpopup" fade={false} isOpen={dueAlert} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>{'Summary of your payment status'}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setDueAlert({ ...dueAlert, visible: false })}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{paddingBottom:"0rem"}}>

            {/* <Box
                      sx={{
                          height: 600,
                          width: '100%',
                          '& .actions': {
                              color: 'text.secondary',
                          },
                          '& .textPrimary': {
                              color: 'text.primary',
                          },
                      }}
                  > */}

            <div>

              {totalSplits?.map(split => {

                let name = "";
                //console.log("cons:", item, totalSplits, item.channel?.channel?.contacts, split.contactId)
                let packageName = item.packages.find(pack => pack.packageId == split.packageId).packageName;

                let contact = item.channel?.channel?.contacts.find(con => con.contactId == split.contactId);
                if (contact) {
                  name = contact.firstName + " " + contact.lastName;

                } else {
                  name = item.regUserDetail['firstName'] + " " + item.regUserDetail['lastName'];
                }
                let actualPrice = split.splitAmount;
                // if (vatPercentage > 0) {
                //   actualPrice = (parseFloat(split.splitAmount) + (parseFloat(split.splitAmount) * (vatPercentage / 100))).toFixed(2);
                // }

                return <>
                  <Col lg='12' className='event-card p-3 mb-4'>

                    <Row>
                      <p>{`Reminder: you colleague ${name} has ordered the following service ${packageName}, make sure that the payment is processed at the earliest to avoid any inconvenience in the event registration and attendance`}</p>
                    </Row>
                    <Row>
                      <Col lg='6' className='pt-3'>

                        <div className='text-left'>
                          {split.paymentDate &&
                            <h4>{"Payment Date: " + Moment(split.paymentDate).format('DD-MM-YYYY')}</h4>}
                          <h4>{"Due Amount: " + actualPrice.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')}{split?.currCode == 'USD' ? ' $' : ' €'}</h4>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </>
              })}


            </div>


            {/* </Box> */}

          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => setManage()}>Ok</Button>
            {/* <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button> */}
          </div>
        </Modal>
      </div>
    )
  }

  const setManage = async (item, totalSplits) => {

    if (totalSplits.filter(x => x.paymentStatus == 0).length) { // && x.paymentMethod == 2).length) {

      let pack = item.packages.find(p => p.packageId == totalSplits[0].packageId);//channel?.package;

      let offers = (pack.offerIds && pack.offerIds != '') ? pack.offerIds.split(',') : [];

      let vatPercentage = 0;

      await commonService.postService({ eventId: item.eventId, offers: offers, channelTypeId: userData.channelTypeId, regType: userData.eventRegisterAs == 1 ? 'Seller' : 'Buyer', channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
        //packOffers = res.data.offers;
        vatPercentage = res.data.hasVat;
        // vatNumber = res.data.vatNumber;
        // isVatCompany = res.data.isVatCompany;
        // offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
      })

      setDueAlert({ ...dueAlert, vatPercentage: vatPercentage, visible: true, item: item, totalSplits: totalSplits.filter(x => x.paymentStatus == 0) });

    }
    else {

      let pack = item.channel?.package;

      let packDetails = {};
      if (pack)
        packDetails = {
          eventId: item.eventId,
          packageId: pack.packageId,
          price: pack.price,
          additionalMembersCosting: pack.additionalMembersCosting,
          membersAllowed: pack.no_of_access_badge,
          packageName: pack.packageName,
          isSeller: pack.isSeller,
          isBuyer: pack.isBuyer,
          additionalMembers: item.channel?.additionalMembers ? item.channel?.additionalMembers : 0,
          marketingOptions: pack.marketingOptions,
          packageOptions: pack.packageOptions
        };


      //For MENA Event redirect to catalyst only for buyer participants
      // if (pack.isBuyer == '1' && item.openCatalyst == 1) {
      if (!isBoFo && item.openCatalyst == 1) {
        getManage(item);
      } else {
        // if (item.eventId == 6 && item.channel.channelId != 1609)
        //   setMsg(true);
        // else {
        dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });
        dispatch({ type: 'EVENT', data: { ...item } });
        // history.push('/admin/' + dashboard + '/eventManage/3');
        if (item.type == 2)// || (item.type == 1 && !pack))
          history.push('events/buyersession/' + item.eventId);
        else if (item.type == 1)// && !pack))
          history.push('/admin/events/eventManagement/' + item.eventId);
        else
          history.push('/admin/events/eventManage');
      }
    }



  }

  const payment = async (item, currentSplit, totalSplit) => {
    var userData = JSON.parse(localStorage.getItem('CHuser'));
    let pack = item.channel?.package;
    let packOffers = []; let vatPercentage; let vatAmount; let offerAmount = 0; let vatNumber; let isVatCompany;
    // console.log("pcka:", pack,split);

    // if (pack.offerIds && pack.offerIds != '') {
    let offers = (pack.offerIds && pack.offerIds != '') ? pack.offerIds.split(',') : [];
    await commonService.postService({ eventId: item.eventId, offers: offers, channelId: userData.channelId, channelTypeId: userData.channelTypeId, regType: userData.eventRegisterAs == 1 ? 'Seller' : 'Buyer', contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
      packOffers = res.data.offers;
      vatPercentage = res.data.hasVat;
      vatNumber = res.data.vatNumber;
      isVatCompany = res.data.isVatCompany;
      offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
    })
    // }

    // console.log("offerAmount:", packOffers, offerAmount, vatPercentage);
    const paymentDetails = {
      actualAmount: pack.price,
      package: [{ pacakgeId: pack.packageId, price: pack.price, packageName: pack.packageName }],
      channelId: userData.channelId,
      contactId: userData.contactId,
      // finalAmount: pack.price - offerAmount,
      finalAmount: currentSplit.splitAmount,//.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.',','),
      eventId: item.eventId,
      offerAmount: offerAmount,
      offers: packOffers,
      packageId: pack.packageId,
      vatPercentage: vatPercentage,
      isVatCompany: isVatCompany,
      vatNumber: vatNumber ? vatNumber : '',//vatPercentage?vatNumber:'',
      isPrimary: true,
      isSeller: item.channel.package['isSeller'],
      isBuyer: item.channel.package['isBuyer'],
      splitId: currentSplit.splitId,
      pennyInvoiceId: currentSplit.pennyInvoiceId,
      paymentMethod: currentSplit.paymentMethod,
      currCode: currentSplit.currCode,
      totalSplit: totalSplit,
      discount: currentSplit.discount
    }

    localStorage.setItem('eventRedirect', JSON.stringify({ paymentDetails: paymentDetails }));
    dispatch({ type: 'EVENT_PAYMENT', data: paymentDetails });

    dispatch({ type: 'EVENT', data: { ...item } });

    history.push('/admin/Subscription/EventPayment');

  }

  //Linked in share methods


  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const getCompanyLogo = async (resImagePath) => {

    setCompanyLogoFlag(false);
    if (resImagePath) {
      console.log("linkeddata:", resImagePath['document'].documentUrl)
      const companyLogo = await getBase64FromUrl(process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath);
      const shareData = { ...linkedData, companyLogo: companyLogo, companyLogoUrl: process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath }
      setlinkedData(shareData);
    }

  };

  const getContactLogo = async (resImagePath) => {

    setContactLogoFlag(false);
    if (resImagePath) {
      // console.log("linkeddata:",resImagePath['document'].documentUrl)
      const contactLogo = await getBase64FromUrl(process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath);
      const shareData = { ...linkedData, contactLogo: contactLogo, companyLogoUrl: process.env.REACT_APP_CDN_URL + resImagePath['document'].documentPath }
      setlinkedData(shareData);
    }


  };

  const editCompanyLogo = () => {
    setCompanyLogoFlag(true);
  }

  const editContactLogo = () => {
    setContactLogoFlag(true);
  }

  const linkedin_share = async () => {
    setLoading(true);

    // const imageData = getBase64FromUrl(linkedData.companyLogo);

    let child = document.getElementById("compedit");
    child.remove();

    const offScreenContainer = document.createElement('div');
    offScreenContainer.style.position = 'absolute';
    offScreenContainer.style.left = '-9999px';
    document.body.appendChild(offScreenContainer);
    let parent = document.getElementById("linkedimage");

    const clonedParent = parent.cloneNode(true);
    offScreenContainer.appendChild(clonedParent);

    // Update styles for the cloned parent element
    clonedParent.style.width = '1200px';
    clonedParent.style.height = '1200px';
    const clonedParentCont = clonedParent.querySelector('#linkcont');
    const clonedParentLogo = clonedParent.querySelector('#linklogo');
    const clonedParentPara = clonedParent.querySelector('#linkpara');

    clonedParentCont.style.bottom = '25.5%';
    clonedParentCont.style.left = '10.5%';
    clonedParentCont.style.width = clonedParentCont.style.height = '300px';

    clonedParentLogo.style.width = clonedParentLogo.style.height = '300px';
    clonedParentLogo.style.display = 'flex';
    clonedParentLogo.style.alignItems = 'center';
    clonedParentLogo.style.justifyContent = 'center';

    clonedParentPara.style.fontSize = '30px';
    clonedParentPara.style.bottom = '11%';
    clonedParentPara.style.left = '10%';

    htmlToImage.toPng(clonedParent).then(function (dataUrl) {
      document.body.removeChild(offScreenContainer);
      selectImg(dataUrl)
      ToastsStore.success('Please wait you will be redirected soon')
    });
  };

  const linkedin_share1 = async () => {
    setLoading(true);

    let child = document.getElementById("conedit");
    child.remove();

    const offScreenContainer = document.createElement('div');
    offScreenContainer.style.position = 'absolute';
    offScreenContainer.style.left = '-9999px';
    document.body.appendChild(offScreenContainer);

    let parent = document.getElementById("linkedimage1");

    const clonedParent = parent.cloneNode(true);
    offScreenContainer.appendChild(clonedParent);

    clonedParent.style.width = '1200px';
    clonedParent.style.height = '1200px';
    const clonedParentCont = clonedParent.querySelector('#linkcont1');
    const clonedParentLogo = clonedParent.querySelector('#linklogo1');
    const clonedParentPara = clonedParent.querySelector('#linkpara1');

    clonedParentCont.style.bottom = '25.5%';
    clonedParentCont.style.left = '10.5%';
    clonedParentCont.style.width = clonedParentCont.style.height = '300px';

    clonedParentLogo.style.width = clonedParentLogo.style.height = '300px';
    clonedParentLogo.style.display = 'flex';
    clonedParentLogo.style.alignItems = 'center';
    clonedParentLogo.style.justifyContent = 'center';

    clonedParentPara.style.fontSize = '30px';
    clonedParentPara.style.bottom = '11%';
    clonedParentPara.style.left = '10%';

    htmlToImage.toPng(clonedParent).then(function (dataUrl) {
      document.body.removeChild(offScreenContainer);
      selectImg(dataUrl)
      ToastsStore.success('Please wait you will be redirected soon')
    });

  };

  const selectImg = async (data) => {
    if (data.length > 0) {
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + ".png";
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append("file", file);
      let aws_folder = "shareinlinkedin";
      const resImagePath = await commonService.fileUploadService(
        formData,
        "/mediaupload/upload/" + aws_folder
      );
      if (resImagePath) {
        console.log("Linked_shareUrl:", resImagePath);
        localStorage.setItem("Linked_shareUrl", resImagePath.data.url);
        setLoading(false);
      } else {
        loading = false;
        setLoading(loading);
      }
    }
    settempModal(false)
    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${Linkedin_ClientId}&redirect_uri=${Linkedin_RedirectUri}&state=random_string&scope=openid profile w_member_social email`, '_self', 'rel=noopener noreferrer');
  };

  const getBase64FromUrl = async (url) => {
    // console.log('url:',url)  

    let body = {
      //token: token, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: url
      //body: fav
    }

    const imageData = await axios.post('channel/getimageforlinkedin', body);


    return imageData.data;


  }

  const temptoggle = async (eventId, shortname, companyName, companyLogo) => {

    sharemodal = false;
    setshareModal(sharemodal);
    console.log("shortname:", shortname, companyName)
    setLoading(true);
    var userData = JSON.parse(localStorage.getItem('CHuser'));

    let companyLogos; let contactLogos;

    if (companyLogo)
      companyLogos = await getBase64FromUrl(process.env.REACT_APP_CDN_URL + companyLogo);
    else
      companyLogos = await getBase64FromUrl('https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg');

    if (userData.documentUrl)
      contactLogos = await getBase64FromUrl(userData.documentUrl);
    else
      contactLogos = await getBase64FromUrl('https://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png');

    let linkedinImagePath = '';
    let sharetitle = '';

    // if (shortname === 'MENA24') {
    //   linkedinImagePath = '/images/Mena_linkedin.jpg';
    //   sharetitle = "MENA";
    // } else if (shortname === 'CSE24') {
    //   linkedinImagePath = '/images/sharetemp.jpg';
    //   sharetitle = "EMEA";
    // }

    linkedinImagePath = `${process.env.REACT_APP_CDN_URL}${socialImage}`
    

    localStorage.setItem("Linked_Share_EventId", eventId);
    const shareData = { shortname: shortname, linkedinImagePath: linkedinImagePath, firstName: userData.firstName, lastName: userData.lastName, companyName: companyName, companyLogo: companyLogos, contactLogo: contactLogos, sharetitle: sharetitle }
    setlinkedData(shareData);
    settempModal(!tempmodal);
    setLoading(false);
  }

  const downloadCompanyShare = () => {
    setLoading(true);

    // const imageData = getBase64FromUrl(linkedData.companyLogo);

    let child = document.getElementById("compedit");
    child.remove();
    let parent = document.getElementById("linkedimage");

    parent.style.width = "1024px";
    parent.style.height = "1024px";
    let parentcont = document.getElementById("linkcont");
    let parentlogo = document.getElementById("linklogo");
    let parentpara = document.getElementById("linkpara");
    parentcont.style.bottom = "31%";
    parentcont.style.left = "14%";
    parentlogo.style.width = parentlogo.style.height = "200px";
    parentpara.style.fontSize = "30px"
    parentpara.style.bottom = "11%"
    parentpara.style.left = "10%"

    htmlToImage.toBlob(parent)
      .then(function (blob) {
        if (window.saveAs) {
          window.saveAs(blob, linkedData.shortname + '-company-share.png');
        }
      });


    setLoading(false);
    settempModal(false);
  }

  const downloadContactShare = () => {
    setLoading(true);

    // const imageData = getBase64FromUrl(linkedData.companyLogo);

    let child = document.getElementById("conedit");
    child.remove();
    let parent = document.getElementById("linkedimage1");

    parent.style.width = "1024px";
    parent.style.height = "1024px";
    let parentcont = document.getElementById("linkcont1");
    let parentlogo = document.getElementById("linklogo1");
    let parentpara = document.getElementById("linkpara1");
    parentcont.style.bottom = "31%";
    parentcont.style.left = "13%";
    parentlogo.style.width = parentlogo.style.height = "200px";
    parentpara.style.fontSize = "30px"
    parentpara.style.bottom = "11%"
    parentpara.style.left = "10%"


    htmlToImage.toBlob(parent)
      .then(function (blob) {
        if (window.saveAs) {
          window.saveAs(blob, linkedData.shortname + '-contact-share.png');
        }
      });


    setLoading(false);
    settempModal(false);
  }

  //Linked in share methods

  const viewManage = (item) => {

    // dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });

    localStorage.setItem('eventDetails', JSON.stringify(item));
    dispatch({ type: 'EVENT', data: { ...item } });
    history.push('events/prevEvent');


  }



  return (

    // <div className="p-3" style={{backgroundColor:"#f8f9fe"}} id='dashslide'>
    <>
      {loading ? <Spinner /> :
        <>
          <Row className="d-flex justify-content-center">

            {dueAlert.visible && duesAlert(dueAlert.item, dueAlert.totalSplits, dueAlert.vatPercentage)}

            {showMsg &&
              <ConfirmDialog open={showMsg} value={{ message: 'The event module will open shortly, check your emails to see the notification message', title: 'Channel Summit', value: null, popUpId: null, second_message: null }} handleSave={displayMsg} handleClose={closeMsg} />}

            {errMsg &&
              <ConfirmDialog open={errMsg} value={{ message: "Maximum limit of participants reached for the selected package. Please contact your company's registered participants for the event", title: 'Retail Connect', value: null, popUpId: null, second_message: null }} handleSave={displayMsg} handleClose={closeMsg} />}

            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
            <Col lg="12" className="p-0" md="12">
              <Card className='m-0 shadecard' style={{ position: 'relative', zIndex: 1 }}>
                <CardBody className='pt-0 pb-0' //style={{ maxHeight: '23rem' }}
                >
                  <Slider {...settings}>

                    {list.length > 0 &&
                      list.map(item => {

                        let companyName = item.channel?.channel?.companyName;

                        let companyLogo = item.channel?.channel?.['channelDetail'].channelLogo?.documentPath;


                        let paymentPending = item.eventPayment.length ? item.eventPayment?.filter(x => x.eventId == item.eventId && x.contactId == item.channel?.registeredBy && x.paymentStatus == '0').length : 0;

                        let unpaidPaymentList = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '0');

                        let unpaidCount = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '0').length;

                        let paidCount = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '1').length;

                        let wireCount = item.eventPayment.filter(x => x.eventId == item.eventId && x.contactId == userData.contactId && x.paymentStatus == '0' && x.wireTransfer == '1').length;

                        let allPaymentList = item.eventPayment.filter(x => x.eventId == item.eventId && x.channelId == userData.channelId);

                        let totalSplits = allPaymentList.sort((a, b) => a.splitId - b.splitId);
                        let eventPayment = unpaidPaymentList.sort((a, b) => a.splitId - b.splitId)[0];



                        let eventAddress = eventData.find(x => x.id == item.eventId);
                        let participant = item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants.find(p => p.eventId == item.eventId);
                        let hideButton = false;
                        if (!hideButton && (userData.channelTypeId != 2 && item.buyerSecondaryToRegister == '0' && userData.roleId != 1) ||
                          (userData.channelTypeId == 2 && ((item.vendorSecondaryToRegister == '0' && userData.roleId != 1) || (item.type == 1 && item.channel?.registeredBy == userData.contactId))))
                          hideButton = true;

                        // let shareData = {};
                        // if (participant?.status == '1' && sharemodal) {
                        //   shareData = { shortname: item.shortname, companyName: companyName, companyLogo: companyLogo };
                        // }

                        return (
                          <div className='dashslide-cont'>
                            <div className="pl-0 pt-4 pb-4 dashslide-left">
                              <div>
                                {/* <h2 className='text-center text-uppercase'>Coming</h2> */}
                                <a href={item?.eventSiteUrl} target='_blank' >
                                  <img
                                    // onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                                    alt="..."
                                    className="img-center img-fluid"
                                    src={item.logo ? process.env.REACT_APP_CDN_URL + item.logo : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'}//{require("assets/img/theme/team-1.jpg")}
                                    style={{ width: "100px" }}
                                  />
                                </a>
                                {timerFlag &&
                                  <DateCountdown className='mt-3' dateTo={item.date_start} mostSignificantFigure='day' numberOfFigures={4} style={{ fontSize: '14px' }} />}
                                <h1 className='text-center Evedat1'>{new Date(item.date_start).getDate()}</h1>
                                <h2 className='text-center text-uppercase'>{month[new Date(item.date_start).getMonth()]}</h2>
                                <div className="text-center buttons-space">
                                  {/* <button className="btn btn-primary ch-btn-blue text-center" variant="contained" color="primary"> Register </button>  */}
                                  {/* {list.length > 0 &&
                      list.map(item => { */}



                                  {/* <> */}
                                  {(item.vendorStatus == 1 || item.vendorStatus == 2 || item.vendorStatus == 4) || (item.buyerStatus == 1 || item.buyerStatus == 2 || item.buyerStatus == 4) && (Moment(item.date_start).format('yyyy') >= 2022 && Moment(item.date_start).format('M') >= 1) ?
                                    <>

                                      {/* {item.type == 1 && item.primaryPending === '1' && item.channel?.paymentstatus=='0' && !participant && (userData.channelTypeId == 2 && item.channel?.registeredBy != userData.contactId && item.type == 1) &&
                                  <>
                                    <Button id='pendingtip' className="btn text-center mt-0" style={{ backgroundColor: "gray", color: "white" }} variant="contained" color="gray">Register</Button>
                                    <UncontrolledTooltip target={'pendingtip'} placement="bottom">You cannot apply yet for the event as one of your colleague is currently pending for approval or payment, please check later</UncontrolledTooltip>
                                  </>
                                } */}

                                      {item.type == 1 && !participant && (paymentPending != 0 || (item.channel && (item.channel?.approvalStatus == null || item.channel?.approvalStatus == 0))) && item.eventStatus != 3 && (item.channel?.registeredBy != userData.contactId && item.type == 1) &&
                                        <>
                                          <Button id='pendingtip' className="btn text-center mt-0" variant="contained" color="grey" style={{ backgroundColor: "gray", color: "white" }}>Register</Button>
                                          <UncontrolledTooltip target={'pendingtip'} placement="bottom">You cannot apply yet for the event as one of your colleague is currently pending for approval or payment, please check later</UncontrolledTooltip>
                                        </>
                                      }

                                      {!hideButton && paymentPending == '0' && (!item.channel || item.channel?.approvalStatus == 1) && ((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !item.statusDate && item.statusDate === '' && item.channel?.registeredBy != userData.contactId && (!item.channel || !participant || (item.type == 1 && item.channel?.approvalStatus == '1' && !item.channel?.channel?.contacts?.length)
                                        || (item.type == 2 && !item.channel?.channel?.contacts?.length)) && item.eventStatus != 3 &&

                                        <Button className="btn btn-primary ch-btn-blue text-center mt-0 " variant="contained" color="primary" type="button"

                                          //disable register button temp)
                                          onClick={() => {
                                            if ((item.vendorStatus == 4 && userData.channelTypeId == 2) || (item.buyerStatus == 4 && userData.channelTypeId != 2)) {
                                              //   if (userData.channelTypeId != 2)
                                              //     setManage(item)
                                              //   else     

                                              if (item.vendorStatus == 4 && userData.channelTypeId == 2)
                                                window.open(item.sellerEventRegisterURL, '_blank');
                                              else if (item.buyerStatus == 4 && userData.channelTypeId != 2)
                                                window.open(item.buyerEventRegisterURL, '_blank');
                                            } else //{
                                              // if (userData.channelTypeId != 2) {
                                              selectEvent(item);
                                            // }
                                            // else
                                            // window.open('https://www.channelconnect1to1.com/select-your-package', '_blank');//'https://www.retailconnect1to1.com/product-page/GRC-ifa-22', '_blank');
                                            // }
                                            //registration(item);
                                          }}
                                        >Register</Button>}
                                      {/* {item.statusDate && item.statusDate!=='' && <Button className='mt-4 mb-3 manag' size="md" type="button" onClick={()=>getManage(item)} >Manage</Button>} */}

                                      {/* Manage Inside CH events */}

                                      {/* {!!item.channel && !!item.channel?.channel?.contacts?.length && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary" 
                                onClick={() => (item.channel?.approvalStatus == 1 && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.status == '1') ? setManage(item) : ''} >
                                  {item.channel?.approvalStatus == '1' && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.status == '1' ? 'Manage' : 'Pending'}</button>}
                              </> : <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary">Completed</button>}
                              */}
                                      {/* Manage Inside CH events */}
                                      {/* prev code(16/8): !!item.channel && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.channelType != 2 && */}
                                      {(paidCount > 0 || unpaidCount == 0) && ((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (item.channel?.registeredBy == userData.contactId && item.type == 1)) && (item.channel?.paymentstatus == '1' || item.channel?.paymentstatus == null) && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (item.channel?.registeredBy == userData.contactId && item.type == 1)) && item.eventStatus != 3 && <><button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                        // onClick={() => (item.channel?.approvalStatus == 1 && participant?.status == '1') ? setManage(item) : ''} >
                                        // onClick={() => (participant?.isEventCatalyst == '1') ? getManage(item) : setManage(item)} >
                                        onClick={() => setManage(item, totalSplits)} >
                                        {/* {item.channel?.paymentstatus == '1' && item.channel?.approvalStatus == '1' && participant?.status == '1' ? 'Manage' : 'Pending'}</button> : */}
                                        Manage</button></>}
                                      {(participant?.shareStatus === '0' || participant?.shareStatus == null && item.eventShareButton ) &&
                                        <button className=" btn btn-primary  mt-0 " style={{ cursor: "pointer" }} onClick={() => {
                                          sharetoggle(item.eventId, item.shortname, companyName, companyLogo)
                                          setSocialImage(item.socialImage);
                                          setPopupImage(`${process.env.REACT_APP_CDN_URL}${item.shareImage}`);
                                          if(userData.documentUrl == null){
                                              setModalPopup(true);
                                              setPopupMessage("Please upload your profile picture on Channel Hub platform to continue");
                                          }else if(jobTitle == null || jobTitle == undefined || jobTitle.length == 0 ){
                                            setModalPopup(true);
                                            setPopupMessage("Please upload your job position on Channel Hub platform to continue");
                                          }

                                        } }> Share</button>}


                                      { sharemodal && isModalPopup ?
                                      <Modal className="sharemodal" id="popup-share-Modal" style={{maxWidth:"50% !important"}} isOpen={sharemodal} toggle={() => setshareModal(false)}>
                                        
                                      <div className="modal-header" >
           <strong>{"Update Your Profile"}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={closePopup}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{marginLeft:"1.25rem", backgroundColor:"transparent"}}>
            {popupMessage}

          </div>
          <div className="modal-footer">
          <Button color="primary" type="button" onClick={confirmPopup}>Ok</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={closePopup}>Close</Button>
          </div>
          
                                    </Modal>
                                      :sharemodal ? 
                                      
                                      <Modal className="sharemodal" isOpen={sharemodal} toggle={() => setshareModal(!sharemodal)}>
                                      <ModalBody>
                                        <div className='row'>
                                          <div className='col-lg-3'>
                                            <div className='sharemodal-left'>
                                              <h2>Share Your Participation!</h2>
                                              <p>Share with your network that you're attending our event!</p>
                                              <div style={{ marginTop: '30px' }}><Button className="btn"
                                                onClick={() => temptoggle(shareData.eventId, shareData.shortname, shareData.companyName, shareData.companyLogo)}

                                              >
                                                <ShareIcon /> Share</Button>
                                                <img onClick={() => linkedin_share()} className='linkedin' src={" /images/in.png "}
                                                // onClick={() => linkedin_share3()}
                                                />
                                                {/* <Button  className="share-linkedin down-btn samp-down-btn" onClick={() => downloadCompanyShare()}>Download</Button> */}

                                              </div>
                                              <div><img className='channel-logo' src={"/images/channesummit.png"} /></div>
                                              <div style={{ cursor: "pointer" }} className='res-close'><span className='sharetemp-close' onClick={() => setshareModal(!sharemodal)}>X</span></div>

                                            </div>
                                          </div>
                                          <div className='col-lg-9' style={{ padding:"0" }}>
                                            <div className="tempeft-image"  id="linkedimage3">
                                              <img src={popupImage}  style={{height:'100%'}}/>
                                              {/* <div className="temp-cont" id="linkcont3">
                                                <div className="temp-logo" id="linklogo3">

                                                  <img src={'/images/francepresident.jpg'} />
                                                </div>
                                              </div>
                                              <p className="temp-para" id="linkpara1" style={{ fontWeight: '600 !important' }}><span>Emmanuel Macron</span><br /> <span>France President</span></p> */}
                                              <div style={{ cursor: "pointer" }} className='des-close'><span className='sharetemp-close' onClick={() => setshareModal(!sharemodal)}>X</span></div>

                                            </div>
                                          </div>
                                        </div>
                                      </ModalBody>
                                    </Modal>
                                    : null
                                       
                                      }
{
     profileModal_isOpen && 
     <Modal className="sharemodal" isOpen={profileModal_isOpen} toggle={() => setProfileModal_isOpen(false)}>
     <ModalBody>
       
       <Userprofileform ratingId={2} forShareImage={"forShareImage"} DashsliderFunction={DashsliderFunction}/>
     </ModalBody>
   </Modal>
}

                                {/* 
                                
                                           <Userprofileform ratingId={2} forShareImage={"forShareImage"}/>
                                */}
                                      {(item.buyerStatus == 2 || item.vendorStatus == 2) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary">Sold Out</button>}

                                      {((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !!item.channel?.channel?.contacts?.length && participant?.isEventCatalyst != '1' && participant?.eventId == item.eventId && participant?.status != '1' && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                      >Pending</button>}

                                      {/* {((userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && !!item.channel?.channel?.contacts?.length && participant?.isEventCatalyst != '1' && (item.channel?.paymentstatus == '0' || item.channel?.paymentstatus == null) && item.channel?.package['price']!=0 && item.channel?.approvalStatus == '1' && participant?.status == '1' && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                  onClick={() => payment(item)}>Payment Pending</button>} */}

                                      {/* before partial payment */}
                                      {/* {(((item.viaEventCatalyst == 0 || item.viaEventCatalyst == null) && userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (item.channel?.paymentstatus == '0' || item.channel?.paymentstatus == null) && item.channel?.wireTransfer==null && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || item.channel?.eventId==item.eventId)&& <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"                                  
                                  onClick={() => payment(item)} >                                 
                                  Payment Pending</button>} */}

                                      {unpaidCount > 0 && paidCount == 0 && wireCount == 0 && (((item.viaEventCatalyst == 0 || item.viaEventCatalyst == null) && userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (eventPayment?.paymentStatus == '0' || eventPayment?.paymentStatus == null) && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || item.channel?.eventId == item.eventId) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                        onClick={() => payment(item, eventPayment, totalSplits)} >
                                        {eventPayment?.paymentMethod == 1 ? "Payment Pending" : "Partial Payment"}</button>}

                                      {/* && (item.channel?.paymentstatus == '0' && item.channel?.wireTransfer=='1' && item.channel?.paymentMethod==2) */}
                                      {wireCount > 0 && paidCount == 0 && (((item.viaEventCatalyst == 0 || item.viaEventCatalyst == null) && userData.channelTypeId == 2 && (item.vendorStatus == 1 || item.vendorStatus == 4)) || (userData.channelTypeId != 2 && (item.buyerStatus == 1 || item.buyerStatus == 4))) && (!!item.channel?.channel?.contacts?.length || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && item.channel?.approvalStatus == '1' && (participant?.status == '1' || (userData.channelTypeId == 2 && item.channel?.registeredBy == userData.contactId && item.type == 1)) && (participant?.eventId == item.eventId || item.channel?.eventId == item.eventId) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary"
                                      // onClick={() => (item.channel?.approvalStatus == 1 && participant?.status == '1') ? setManage(item) : ''} >
                                      // onClick={() => payment(item)}
                                      >
                                        {/* {item.channel?.paymentstatus == '1' && item.channel?.approvalStatus == '1' && participant?.status == '1' ? 'Manage' : 'Pending'}</button> : */}
                                        Payment Approval</button>}


                                      {/* Waiting for opening in event catalyst */}
                                      {/* prev code (16/8):  */}
                                      {/* {!!item.channel && !!item.channel?.channel?.contacts?.length && item.channel?.approvalStatus == 1 && participant?.status == '1' && participant?.isEventCatalyst == '1' &&
                                  <button className="btn btn-primary ch-btn-blue text-center mt-0 meet" variant="contained" color="primary" onClick={() => getManage(item)}>
                                    {participant?.isEventCatalyst == '1' ? 'Managess' : 'Pending'}
                                  </button> //Meeting Scheduler  replaced by manage
                                } */}

                                    </>
                                    //  prev code (16/8): userData.channelTypeId != 2 &&
                                    : (item.vendorStatus == 3 || item.buyerStatus == 3) && <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary">Completed</button>}


                                  {/* {item.channel?.channel?.contacts?.length == 0 && item.channel?.approvalStatus == null && <>
                              <Button id={'view' + item.eventId} className='mt-4 mb-3 btn btn-success' size="sm" type="button">Waiting</Button>
                              <UncontrolledTooltip target={'view' + item.eventId} placement='bottom'>
                                Wait for Main Delegate Approval
                              </UncontrolledTooltip></>} */}

                                  {/* {item.eventStatus == 3 && item.channel?.channel?.contacts.length > 0 && item.channel?.channel?.contacts[0]?.eventParticipants[0]?.isEventCatalyst == '1' ?
                              <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary" onClick={() => setManage(item)}>Manage</button> :
                              ((item.eventStatus != 1 && item.eventStatus != 2) && <button className="btn btn-primary ch-btn-blue text-center mt-0 meet" variant="contained" color="primary" >
                                Completed
                              </button>)
                            } */}

                                  {item.eventStatus === 3 ? item.channel?.channel?.contacts?.find(con => con.contactId == userData.contactId)?.eventParticipants?.filter(p => p.eventId == item.eventId).length > 0 ? // && participant?.isEventCatalyst == '1' ?
                                    <button className="btn btn-primary ch-btn-blue text-center mt-0" variant="contained" color="primary" onClick={() => viewManage(item)}>View</button> :
                                    ((item.eventStatus != 1 && item.eventStatus != 2) && <button className="btn btn-primary ch-btn-blue text-center mt-0 " variant="contained" color="primary" >
                                      Completed
                                    </button>)
                                    :
                                    <></>
                                  }

                                  {/* </> */}
                                  {/* ) */}
                                  {/* })} */}
                                </div>
                              </div>
                            </div>
                            {/* <Col lg='1' className='botEve'></Col> */}
                            <div className='pl-5 pt-4 pb-4 botEve dashslide-right'>
                              <h1 id='eHead' className='text-left font-weight-light headeve' style={{ fontSize: "2rem" }}><a href={item.eventSiteUrl} target='_blank'>{item.event_name}</a></h1>
                              <p>({new Date(item.date_start).toUTCString().substring(0, 16) + " - " + new Date(item.date_end).toUTCString().substring(0, 16)})</p>
                              <UncontrolledTooltip target={'eHead'} placement="bottom">{item.event_name}</UncontrolledTooltip>
                              <div className='pb-2' style={{ height: '5rem', overflowY: 'hidden' }}>
                                <p>{item.description}</p>
                              </div>
                              <hr style={{ marginBottom: '1rem' }} />



                              <p><i class="fas fa-map-marker-alt"></i> {eventAddress.address + " - " + eventAddress.country}</p>


                              {/* <div className='d-flex justify-content-start'>
                  {eventData[0].buyers.split(",").map(logo =>
                    <div>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img alt="..." className="rounded-circle img-center img-fluid shadow shadow-lg--hover mr-3"
                          src={logo ? process.env.REACT_APP_CDN_URL + logo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                          style={{ width: "75px" }}
                        />
                        
                      </a>
                    </div>
                  )}
                </div> */}

                            </div>
                          </div>
                        )

                      })}


                  </Slider>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div style={{
            display: 'block', padding: 30
          }}>
            {companyLogoFlag &&
              <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={3} size={1}
                img={linkedData.companyLogoUrl ? linkedData.companyLogoUrl : process.env.REACT_APP_CDN_URL +
                  "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />}

            {contactLogoFlag &&
              <ImageLibrary getDocumentId={getContactLogo} aws_folderName='CompanyLogo' id={3} size={1}
                img={linkedData.companyLogoUrl ? linkedData.companyLogoUrl : process.env.REACT_APP_CDN_URL +
                  "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />}



            <Modal className="tempevent-modal" style={{maxWidth:"800px"}} isOpen={tempmodal} toggle={() => settempModal(!tempmodal)}>
              <ModalBody style={{paddingBottom:"0rem"}}>
              <h2 className='Nwsovr' style={{textAlign:"center", fontSize:"18px"}}>Inform your Linkedin network about your participation to Channel Summit {linkedData.sharetitle} in just 1 click.</h2>
                <div className="row">
                  {/* <div className="col-lg-6">
                    <div className="tempevent-left">
                      <h4 style={{fontSize:"12px"}}>Share Your Company Participation</h4>
                      <div className="tempeft-image" id="linkedimage">
                        <img src={linkedData.linkedinImagePath} />
                        <div className="temp-cont" id="linkcont">
                          <div className="temp-logo" id="linklogo">
                            <span className="temp-edit" id="compedit"><EditIcon onClick={() => editCompanyLogo()} /></span>

                            <img src={linkedData.companyLogo} />
                          </div>
                        </div>
                        <p className="temp-para" id="linkpara"><span>{linkedData.firstName}</span> <span>{linkedData.lastName}</span><br /><span>{linkedData.companyName}</span><br /><span></span></p>

                      </div>
                      <Button className="share-linkedin" style={{border:"none", fontSize:"12px"}} onClick={() => linkedin_share()}>Share in linkedin</Button>
                      <Button className="share-linkedin down-btn" style={{border:"none", marginLeft:"14px", fontSize:"12px"}} onClick={() => downloadCompanyShare()}>Download</Button>
                    </div>
                  </div> */}
                  <div className="col-lg-12" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <div className="tempevent-right">
                    <h4 style={{fontSize:"14px"}}>Share Your Participation</h4>
                      <div className="tempeft-image" id="linkedimage1">
                        <img src={linkedData.linkedinImagePath} />
                        <div className="temp-cont" id="linkcont1">
                          <div className="temp-logo" id="linklogo1">
                            <span className="temp-edit" id="conedit"><EditIcon onClick={() => editContactLogo()} /></span>
                              
                            <img id='temp-log0-userImg' src={linkedData.contactLogo} />
                          </div>
                        </div>
                        <p className="temp-para" id="linkpara1">
                          
                          <span>{linkedData.firstName}</span> <span>{linkedData.lastName}</span><br /><span style={{fontWeight:"400"}}>{jobTitle}</span><br /><span>{linkedData.companyName}</span><br /><span></span> 
                          

                          </p>

                      </div>
                      <Button className="share-linkedin" style={{border:"none", fontSize:"12px", marginTop:"2rem"}} onClick={() => linkedin_share1()}>Share in linkedin</Button>
                      <Button className="share-linkedin down-btn" style={{border:"none", marginLeft:"14px", fontSize:"12px", marginTop:"2rem"}} onClick={() => downloadContactShare()}>Download</Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" style={{fontSize:"12px"}} onClick={() => settempModal(!tempmodal)}>Close</Button>
              </ModalFooter>
            </Modal>
          </div>
        </>}
    </>

  );
};
export { Dashslider, DashVenslider, DashEventlist };