import React, { useState, useEffect } from "react";
import { FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { Container, Card, CardBody, Row, Col, Button, CardImg,  Form, CardHeader } from "reactstrap";
import GuestFooter from "./GuestNavbar/GuestFooter";
import GuestNavBar from "./GuestNavbar/GuestNavbar";
import { Link } from "react-router-dom";
import GuestProductlist from "./GuestNew/GuestProductlist";
import { commonService } from "_services/common.service";
import GuestViewContact from "../../Hoc/Dialog/GuestViewContact";
import axios from 'axios';
import firstImg from 'assets/img/guestpage/business.jpg';
import secondImg from 'assets/img/guestpage/closeup.jpg';
import thiredImg from 'assets/img/guestpage/desk-gadgets.jpg';
import fourth from 'assets/img/guestpage/smartphone-with-earphones.jpg';
import { useForm } from "react-hook-form";
import { history } from "_helpers/history";

import { browserName, browserVersion } from "react-device-detect";
import GuestTopSearch from "./GuestNavbar/GuestTopSearch";
import HomeLatestVendor from "../Home/HomeLatestVendor";
import NextEventPlane from "../Home/NextEventPlane";
const GuestBuyerNew = (props) => {
  const options = top100Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });
  const { register, control } = useForm({ mode: 'onBlur' });
  const [login, setLogin] = useState(false);
  const [categoriesOption, setCategoriesOption] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [parent, setParent] = useState(null);

  const getCategories = async () => {
    let data = {
      countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null
    }
    await commonService.postService(data, 'guest/getCategories').then(res => {
      setCategoriesOption(res.data.productCat);
    });
  }



  const [countriesOption, setCountriesOption] = useState([]);
  const [countriesValue, setCountriesValue] = useState([]);
  const [flag, setFlag] = useState(false);

  const getCountries = async () => {
    let data = {
      categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null
    }
    await commonService.postService(data, 'guest/country/').then(res => {
      setCountriesOption(res.data.countries);
    });
  }


  useEffect(() => {
    getCategories();
    getCountries();
    updatenavigation();
  }, [])

  const updatenavigation = async () => {
    let navigationData = {}
    navigationData.browser = browserName + ' , Version :' + browserVersion;;
    navigationData.navigatedPage = window.location;
    axios.post('/guest/updateNavigationHistory', navigationData);
  }

  useEffect(() => {
    if (flag) {
      setViewResult(false);
      if (parent !== 'category')
        getCategories();
      else if (parent !== 'country')
        getCountries();
      getFilterData();
    }
    setFlag(true)
  }, [categoriesValue, countriesValue])

  const [search, setSearch] = useState({});
  const [viewResult, setViewResult] = useState(false);

  const getFilterData = async () => {
    let data = {
      filter: {
        categoryId: categoriesValue.length ? categoriesValue.map(x => +x.childid) : null,
        countryId: countriesValue.length ? countriesValue.map(x => +x.childId) : null,
      }
    }

    await commonService.postService(data, 'guest/buyerSearchCount').then(res => {
      setSearch(res.data);
      if (categoriesValue.length || countriesValue.length)
        setViewResult(true);
    })

  }

  const [video, setVideo] = useState({
    video1: false,
    video2: false,
    video3: false
  });



  const playVideo = (video1) => {
    video[video1] = !video[video1];
    setVideo({ ...video });
    var myVideo = document.getElementById(video1);
    if (myVideo) {
      if (video[video1])
        myVideo.play();
      else
        myVideo.pause();
    }
  }
  const openLogin = () => {
    localStorage.removeItem('guest-product-fav', props.match.params.id);
    localStorage.removeItem('guest-channel-fav', props.match.params.id);
    setLogin(true);
  }
  const handleClose = () => {
    setLogin(false);
  }

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'Category') {
      if (countriesValue.length == 0)
        setParent('category');
      setCategoriesValue(value);
    }
    if (input === 'Country') {
      if (categoriesValue.length == 0)
        setParent('country');
      setCountriesValue(value);
    }
  }

  const [ImgURL, setImgURL] = useState({});

  useEffect(() => {
    const images = [
      { url: secondImg },
      { url: firstImg },
      { url: thiredImg },
      { url: fourth }
    ];
    let x = Math.floor((Math.random() * 4) + 0);
    const ImgURL1 = {
      background: "linear-gradient(to right,#2ca5cdc7, #e8870194), url(" + images[x].url + ") no-repeat top center",
      padding:"192px 192px"
    };
    setImgURL(ImgURL1);
  }, [])
  return (
    <div className="main-content">
      <GuestNavBar />
      <GuestViewContact type="buyer" open={login} handleClose={handleClose} />
      <Card className="hero-section mb-0 text-center" style={ImgURL}>
 
   <Form className="topSearchge1">
     <GuestTopSearch   GuestBuyerNew={true}/>
   </Form>
 </Card>
    
      <Card style={{margin:"0"}}> 
        <CardBody style={{backgroundColor:"#f7f7f7", padding:"3rem"}}>

          
        <div style={{ fontSize: '1.2rem', textAlign: 'center', color: 'rgb(50, 50, 93)', paddingBottom: '0.9rem', fontWeight:"600", fontFamily:"inherit" }}>
        DISCOVER NEW BRANDS AND PRODUCTS FROM AROUND THE WORLD AND IMPROVE YOUR ASSORTMENT.
          </div> 
            <p className="text-muted" style={{fontSize:"1rem", textAlign:"center", fontFamily: 'Open Sans, sans-serif'}}>Tell us what product category you are looking for and we’ll show you matching brands and connect you with the right contact person in just a few clicks. 
            </p> 
              <div className="container"><p style={{fontSize:"15.6px", textAlign:"center", fontFamily: 'Open Sans, sans-serif', margin:"0"}}>Hundreds of select vendors are listed on ChannelHub. Each vendor is carefully evaluated before being listed. All registered vendors have a complete company profile including corporate videos and contact names. Based on your preferences, we’ll suggest you the best matches and you’ll be able to contact them.</p></div>
        </CardBody>
      </Card>
 
 <HomeLatestVendor buyerPage={true}/>
 <Card style={{backgroundColor:"#f7f7f7",  margin:"0"}}> 
          
        <CardBody style={{padding:"3rem"}}>
        <div
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              color: "rgb(50, 50, 93)", 
              fontWeight: "600",
              fontFamily: "inherit",
              paddingBottom:"2rem"
            }}
          >
            Next Events
          </div>
             <div style={{ fontSize: '1.2rem', textAlign: 'center', color: 'rgb(50, 50, 93)', paddingBottom: '2rem', fontWeight:"600", fontFamily: 'Open Sans, sans-serif'}}>
     Attend our event with our hosted buyer package
          </div> 
              <div className="container"><p style={{fontSize:"1rem", textAlign:"center", fontFamily: 'Open Sans, sans-serif', paddingBottom:"3rem", margin:"0"}}>Join us at our next events with our exclusive hosted buyer package! In addition to our online platform, ChannelHub is organizing two annual events featuring hosted buyer invitations and pre-scheduled one-on-one meetings, combined with high-level networking opportunities. Secure your invitation package for our next event, which includes complimentary accommodation at a 5-star Fairmont property, all meals, activities, conference sessions, and much more.</p></div>
      <NextEventPlane />

        </CardBody>
      </Card>

      <GuestFooter />

    </div>
  );
};

export default GuestBuyerNew;
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 }];